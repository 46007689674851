import { useContext, useEffect } from 'react';
import { TunisiaClockContext } from '../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import FlipNumbers from 'react-flip-numbers';
import DynamicSvg from '../../../../DynamicSvg/DynamicSvg';
import Loader from '../../../../Loader/Loader';
import Insight from './Insight/Insight';
import 'leaflet/dist/leaflet.css';
import useToggle from '../../../../../custom-hooks/useToggle';
import Slider from '../../../../SliderRange/Slider';
import RegionsAndSectors from './RegionsAndSectors/RegionsAndSectors';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import {
  removeActiveItemFromList,
  flipNumbersStyles,
  findSelectedRegion,
  percentageOfWorkingAgePopulation,
  sortTunisiaRegions,
} from '../../../../../utils/common';
import arrow from '../../../../../assets/images/common/arrow.svg';
import FlipArrow from '../../../../../assets/images/hero_section/flipArrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import CustomSlider from '../../../../SliderRange/Slider';

const Breakdowns = ({ data, tunisiaRegions, insightsData }) => {
  const [state, dispatch] = useContext(TunisiaClockContext);
  const [{ regionsToggle, insightsToggle }, handleToggleState] = useToggle();

  useEffect(
    () =>
      dispatch({
        type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
        payload: {
          type: 'activeSector',
          name: data && data.values[0].superSectors[0].sector.name,
        },
      }),
    [data, dispatch]
  );

  return (
    <article
      css={[
        tw`flex justify-between flex-col pr-[11.3125rem] max-w-[51rem] ml-auto w-full z-[1]`,
      ]}
    >
      <div>
        <div
          css={[
            insightsToggle &&
              state.showBreakdownBar &&
              tw`flex items-center justify-between`,
          ]}
        >
          <div tw="flex justify-between items-center opacity-100 transition duration-500 relative">
            <ul tw="flex items-center max-w-[12rem] mt-0">
              <li
                tw="hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem] mr-[0.625rem] justify-between"
                onClick={() =>
                  handleToggleState(regionsToggle, 'regionsToggle')
                }
              >
                {state.activeRegion.id === 'Tunisia'
                  ? t('GLOBAL_TUNISIA')
                  : state.activeRegion.name}
                <img
                  css={[
                    tw`ml-[1rem] transition duration-500`,
                    regionsToggle && tw`rotate-180`,
                  ]}
                  alt="Arrow"
                  width={14}
                  src={arrow}
                />
                {regionsToggle && (
                  <ul
                    tw="text-gray-800 text-xs absolute top-[2.9rem] left-0 w-[8rem] cursor-pointer shadow bg-white max-h-[21rem] overflow-y-scroll text-left z-[2]"
                    className="scrollable scrollableRegions"
                  >
                    {sortTunisiaRegions(
                      tunisiaRegions,
                      state.activeRegion.id,
                      dispatch
                    )}
                  </ul>
                )}
              </li>
              <li>{t('HEADER_REGION_HAS')}</li>
            </ul>
          </div>
          <div tw="flex items-center p-[0.5rem 0 1rem] gap-[0 0.5rem]">
            <span tw="font-semiBold mr-[0.625rem] h-[3rem] flex relative min-w-[5rem] text-green-400">
              {!data?.overall?.value ? (
                <Loader message="" />
              ) : (
                <FlipNumbers
                  duration={1}
                  play
                  numbers={findSelectedRegion(
                    data?.values,
                    state.activeRegion.id
                  )?.overall?.value.toLocaleString()}
                  width={35}
                  height={80}
                  numberStyle={flipNumbersStyles}
                  nonNumberStyle={flipNumbersStyles}
                  perspective={100000}
                />
              )}
            </span>
            <DynamicSvg iconName={data?.overall?.direction} size={24} />
            <InfoTooltip />
          </div>
        </div>
        <div tw="text-regular pb-[0.75rem] flex">
          {isNaN(
            percentageOfWorkingAgePopulation(state.ages, state.genders.id, data)
          ) ? (
            <span tw="relative w-[3rem]">
              <Loader message="" />
            </span>
          ) : (
            <span tw="pr-[0.25rem] text-green-400">
              {percentageOfWorkingAgePopulation(
                state.ages,
                state.genders.id,
                data
              )}
              %
            </span>
          )}
          <span> {t('HEADER_TUNISIA_PERCENT_OF')}</span>
        </div>
        <div>
          {state.activeSector && <RegionsAndSectors data={data} />}
          <div tw="w-full mb-[1rem] rounded-[1.25rem] border border-gray-100 shadow p-[0.75rem 1rem] cursor-pointer">
            <div
              tw="flex justify-between"
              onClick={() =>
                handleToggleState(insightsToggle, 'insightsToggle')
              }
            >
              <h3 tw="text-blue-400 text-xs font-medium">
                {t('FILTERS_INSIGHTS')}
              </h3>
              <img
                css={[
                  tw`transition duration-500`,
                  insightsToggle && tw`rotate-180`,
                ]}
                alt="Flip arrow"
                src={FlipArrow}
              />
            </div>
            {insightsToggle && insightsData && (
              <Insight
                employmentsMapSection={state.employmentsMapSection}
                insightsData={insightsData}
              />
            )}
          </div>
        </div>
      </div>
      <CustomSlider isFromMapSection={true} />
    </article>
  );
};

export default Breakdowns;

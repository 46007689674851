/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Popup = ({ message, isFromEmployments, isFromEmploymentsMap }) => (
  <div
    css={[
      tw`h-0 invisible opacity-0 transition duration-500 bg-gray-500 text-left absolute rounded-[0.5rem] p-[1rem] w-auto bottom-[3rem] text-white text-xs z-[402]`,
      isFromEmployments &&
        tw`top-[4rem] bottom-auto fixed max-w-[18rem] w-full`,
      isFromEmploymentsMap && tw`w-[18rem]`,
    ]}
  >
    {message}
  </div>
);

export default Popup;

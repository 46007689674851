import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import DynamicSvg from '../../../../../../../DynamicSvg/DynamicSvg';
import { checkDataLength } from '../../../../../../../../utils/common';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Sectors = ({ data }) => {
  const [{ activeSector }, dispatch] = useContext(TunisiaClockContext);

  return (
    <article
      className="scrollable scrollableRegions"
      css={[
        tw`border-r-[0.063rem] border-gray-100 pr-[0.75rem]`,
        checkDataLength(data) && tw`max-h-[13.3rem] overflow-y-scroll w-full`,
      ]}
    >
      <h4 tw="text-gray-500 pb-[1.5rem] font-semiBold text-xs">
        {t('GLOBAL_SECTORS')}
      </h4>
      {data?.map((item) => (
        <button
          key={item?.sector?.id}
          css={[
            tw`flex justify-between w-[16.875rem] border border-blue-400 rounded-[3.37rem] p-[0.5rem 1rem] hover:bg-blue-200 text-gray-800 mb-[0.5rem]`,
            activeSector === item?.sector?.id &&
              tw`bg-blue-400 text-white hover:bg-blue-400`,
          ]}
          name={item?.sector?.id}
          onClick={({ currentTarget }) => {
            if (currentTarget.name !== activeSector) {
              dispatch({
                type: 'SET_ACTIVE_FILTER',
                payload: {
                  type: 'activeSubSector',
                  name: '',
                },
              });
              dispatch({
                type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
                payload: { type: 'activeSector', id: item?.sector?.id },
              });
            }
          }}
        >
          <span tw="flex justify-between items-center gap-[0.5rem]">
            <DynamicSvg
              iconName={item?.sector?.id}
              size={16}
              fill={activeSector === item?.sector?.id ? 'white' : 'black'}
            />
            <p tw="pr-[2rem] text-sm">{item?.sector?.name}</p>
          </span>
          <span
            css={[
              tw`flex justify-between items-center gap-[0.5rem] text-sm font-medium text-blue-400`,
              activeSector === item?.sector?.id && tw`text-white`,
            ]}
          >
            <p>{item?.overall?.value.toLocaleString()}</p>
            <DynamicSvg iconName={item?.overall?.direction} size={10} />
          </span>
        </button>
      ))}
    </article>
  );
};

export default Sectors;

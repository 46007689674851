import Employed from './Employed/Employed';
import Inactive from './Inactive/Inactive';
import Unemployed from './Unemployed/Unemployed';
import InactiveAndUnemployed from './InactiveAndUnemployed/InactiveAndUnemployed';

const Breakdowns = ({
  data,
  tunisiaRegions,
  employmentsMapSection,
  insightsData,
}) =>
  employmentsMapSection === 'GLOBAL_EMPLOYED' ? (
    <Employed
      data={data}
      tunisiaRegions={tunisiaRegions}
      insightsData={insightsData}
    />
  ) : employmentsMapSection === 'GLOBAL_INACTIVE' ? (
    <Inactive
      data={data}
      tunisiaRegions={tunisiaRegions}
      insightsData={insightsData}
    />
  ) : employmentsMapSection === 'GLOBAL_UNEMPLOYED' ? (
    <Unemployed
      data={data}
      tunisiaRegions={tunisiaRegions}
      insightsData={insightsData}
    />
  ) : (
    <InactiveAndUnemployed
      data={data}
      tunisiaRegions={tunisiaRegions}
      insightsData={insightsData}
    />
  );

export default Breakdowns;

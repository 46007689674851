import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import React, { useContext } from "react";
import { sliderMarks } from "../../utils/slider";
import { TunisiaClockContext } from "../../views/TunisiaClock/TunisiaClockContextProvider";
/** @jsxImportSource @emotion/react */
import tw from "twin.macro";

const StyledYearsSlider = styled(Slider)(() => ({
    ".MuiSlider-mark": {
        borderRadius: 50,
        color: "#5B9ADF",
        height: "0.313rem",
        width: "0.313rem"
    },
    ".MuiSlider-markLabel": {
        color: "#ADADAD",
        fontSize: "0.85rem",
        fontWeight: 400
    },
    ".MuiSlider-markLabelActive": {
        fontWeight: 500,
        color: "#252525"
    },
    ".MuiSlider-rail": {
        color: "#5B9ADF",
        height: "1px",
        opacity: 1
    },
    ".MuiSlider-thumb": {
        height: 28,
        width: 28,
        backgroundColor: "#fff",
        border: "solid 2px #3A7CC5"
    },
    ".MuiSlider-markActive": {
        color: "#252525"
    }
}));

const CustomSlider = ({ isFromMapSection }) => {
    const [{ selectedYear }, dispatch] = useContext(TunisiaClockContext);

    const handleChange = (_, value) => {
        dispatch({
            type: "SET_ACTIVE_FILTER",
            payload: { type: "selectedYear", name: value }
        });
    };

    const handleMarks = () => {
        let newMarks = Array.from(
            { length: 5 },
            (_, index) => sliderMarks[0].value + 5 * index
        );
        if (!newMarks.includes(selectedYear)) {
            const indexPos = newMarks.findIndex((item) => item > selectedYear);
            newMarks.splice(indexPos, 0, selectedYear);
        }
        newMarks = newMarks.map((item) => ({
            label: item.toString(),
            value: item
        }));
        return newMarks;
    };

    return (
        <div
            css={[
                tw`w-[calc(100% - 22.625rem)] m-[0px 11.3125rem]`,
                isFromMapSection && tw`relative w-full bottom-[3rem] p-0 m-0`
            ]}
        >
            <StyledYearsSlider
                track={false}
                aria-label="Custom marks"
                getAriaValueText={(value) => value.toString()}
                marks={isFromMapSection ? handleMarks() : sliderMarks}
                min={2015}
                max={2030}
                onChange={handleChange}
                value={selectedYear}
                tw="m-0 p-0"
            />
        </div>
    );
};

export default CustomSlider;

import { t } from 'i18next';
import DynamicSvg from '../../../../../../../DynamicSvg/DynamicSvg';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';

const AgeAndGenderInner = ({
  data,
  ages,
  genders,
  type,
  employmentsMapSection,
}) => (
  <article tw="pl-[0.75rem] w-full p-0 pt-[1rem] text-[0.875rem]">
    {employmentsMapSection === 'GLOBAL_INACTIVE_UNEMPLOYED' && (
      <div tw="flex items-center justify-between pb-[0.25rem]">
        <h4 tw="text-gray-800 text-sm font-medium">{type}</h4>
        <div
          css={[
            tw`flex items-center justify-between gap-[0.5rem] font-semiBold`,
            type === 'GLOBAL_INACTIVE'
              ? tw`text-ochre-500`
              : tw`text-orange-500`,
          ]}
        >
          <span>{data?.overall?.value.toLocaleString()}</span>
          <DynamicSvg iconName={data?.overall?.direction} size={10} />
        </div>
      </div>
    )}
    <div
      css={[
        tw`flex items-center justify-between text-gray-500 font-semiBold border-b border-b-gray-100 p-[0.5rem]`,
        employmentsMapSection === 'GLOBAL_INACTIVE_UNEMPLOYED' &&
          tw`border-t border-t-gray-100`,
      ]}
    >
      <span>{t('GLOBAL_AGE')}</span>
      <DynamicSvg
        iconName="MaleGender"
        size={16}
        fill={theme`colors.gray.500`}
      />
      <DynamicSvg
        iconName="FemaleGender"
        size={16}
        fill={theme`colors.gray.500`}
      />
    </div>
    {data?.values.map(({ ageGroup, females, males }) => (
      <div
        key={ageGroup?.from}
        tw="flex justify-between items-center p-[0.20rem 0.5rem] even-of-type:bg-inactive"
      >
        <span
          css={[
            tw`text-gray-800 text-sm w-[20%]`,
            `${ageGroup.from} - ${ageGroup.to}` === ages.name ||
            ages.id === 'All'
              ? tw`opacity-100`
              : tw`opacity-25`,
          ]}
        >
          {`${ageGroup?.from} - ${ageGroup?.to}`}
        </span>
        <span
          css={[
            tw`font-medium text-sm flex gap-[0.5rem] items-center flex-1 justify-end`,
            type === 'GLOBAL_INACTIVE'
              ? tw`text-ochre-500`
              : tw`text-orange-500`,
            (genders.id === 'Males' || genders.id === 'All genders') &&
            (`${ageGroup.from} - ${ageGroup.to}` === ages.name ||
              ages.id === 'All')
              ? tw`opacity-100`
              : tw`opacity-25`,
          ]}
        >
          {males?.value.toLocaleString()}
          <DynamicSvg iconName={males?.direction} size={10} />
        </span>
        <span
          css={[
            tw`font-medium text-sm flex gap-[0.5rem] items-center flex-1 justify-end`,
            type === 'GLOBAL_INACTIVE'
              ? tw`text-ochre-500`
              : tw`text-orange-500`,
            (genders.id === 'Females' || genders.id === 'All genders') &&
            (`${ageGroup.from} - ${ageGroup.to}` === ages.name ||
              ages.id === 'All')
              ? tw`opacity-100`
              : tw`opacity-25`,
          ]}
        >
          {females?.value.toLocaleString()}
          <DynamicSvg iconName={females?.direction} size={10} />
        </span>
      </div>
    ))}
    <div tw="flex justify-between items-center p-[0.20rem 0.5rem] even-of-type:bg-tableBlue">
      <span
        css={[
          tw`text-gray-800 text-sm w-[20%]`,
          ages.id === 'All' ? tw`opacity-100` : tw`opacity-25`,
        ]}
      >
        Total
      </span>
      <span
        css={[
          tw`font-medium text-sm flex gap-[0.5rem] items-center flex-1 justify-end`,
          type === 'GLOBAL_INACTIVE' ? tw`text-ochre-500` : tw`text-orange-500`,
          (genders.id === 'Males' || genders.id === 'All genders') &&
          ages.id === 'All'
            ? tw`opacity-100`
            : tw`opacity-25`,
        ]}
      >
        {data?.males?.value.toLocaleString()}
        <DynamicSvg iconName={data?.males?.direction} size={10} />
      </span>
      <span
        css={[
          tw`font-medium text-sm flex gap-[0.5rem] items-center flex-1 justify-end`,
          type === 'GLOBAL_INACTIVE' ? tw`text-ochre-500` : tw`text-orange-500`,
          (genders.id === 'Females' || genders.id === 'All genders') &&
          ages.id === 'All'
            ? tw`opacity-100`
            : tw`opacity-25`,
        ]}
      >
        {data?.females?.value.toLocaleString()}
        <DynamicSvg iconName={data?.females?.direction} size={10} />
      </span>
    </div>
  </article>
);

export default AgeAndGenderInner;

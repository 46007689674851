import { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { TunisiaClockContext } from '../../../../views/TunisiaClock/TunisiaClockContextProvider';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import Loader from '../../../Loader/Loader';
import { regionStyle } from '../../../../utils/leaflet';
import {
  useRegionsCoordinatesQuery,
  useRegionsStatsQuery,
  useNeighbourCountriesWithCoordinatesQuery,
} from '../../../../generated/graphql.d.ts';
import {
  jsonParse,
  findRegionOverall,
  tunisiaPopulation,
  findRegionByRegionID,
} from '../../../../utils/common';
import TooltipContainer from './TooltipInfo/TooltipContainer';
import back from '../../../../assets/images/map_section/back.svg';
/** @jsxImportSource @emotion/react */
import { theme } from 'twin.macro';
import { t } from 'i18next';

const centerMap = [34, 9.5];

const Map = () => {
  const [
    {
      employmentsMapSection,
      employmentsMatchingTheGaps,
      activeSection,
      activeRegion,
      selectedYear,
      isLoadingNewMapRegionsData,
    },
    dispatch,
  ] = useContext(TunisiaClockContext);
  const [tooltipInfo, setTooltipInfo] = useState(null);
  const [firstRender, setFirstRender] = useState(true);
  const [zoomedToRegion, setZoomedToRegion] = useState(false);
  const geoJsonRef = useRef();
  const [mapRef, setMapRef] = useState(null);

  const resetZoom = useCallback(() => {
    dispatch({
      type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
      payload: {
        type: 'activeRegion',
        name: 'GLOBAL_TUNISIA',
        id: 'Tunisia',
      },
    });
    setZoomedToRegion(false);
    setFirstRender(true);
    mapRef?.target?.flyTo(centerMap, 6.5);
  }, [mapRef?.target, dispatch]);

  useEffect(() => {
    if (activeRegion.id === 'Tunisia') {
      setZoomedToRegion(false);
      setFirstRender(true);
    } else {
      const selectedRegion = geoJsonRef?.current?._layers && Object.values(geoJsonRef?.current?._layers).find(
        (region) => region.feature.id === activeRegion.id
      );
      selectedRegion && applyMapStyle({ target: selectedRegion });
    }

  }, [activeRegion, selectedYear]);

  const options = {
    variables: {
      year: selectedYear,
      withNumTotal: true,
      withNumEmployed:
        activeSection === 'MENU_MATCHING_GAPS' ||
        (activeSection === 'MENU_WORKFORCE' &&
          employmentsMapSection.name === 'GLOBAL_EMPLOYED'),
      withNumUnemployed:
        employmentsMapSection.name === 'GLOBAL_INACTIVE_UNEMPLOYED' ||
        employmentsMapSection.name === 'GLOBAL_UNEMPLOYED' ||
        employmentsMatchingTheGaps.name === 'GLOBAL_INACTIVE_UNEMPLOYED' ||
        employmentsMatchingTheGaps.name === 'GLOBAL_UNEMPLOYED',
      withNumInactive:
        employmentsMapSection.name === 'GLOBAL_INACTIVE_UNEMPLOYED' ||
        employmentsMapSection.name === 'GLOBAL_INACTIVE' ||
        employmentsMatchingTheGaps.name === 'GLOBAL_INACTIVE_UNEMPLOYED' ||
        employmentsMatchingTheGaps.name === 'GLOBAL_INACTIVE',
      withNumOpenJobs:
        activeSection === 'MENU_MATCHING_GAPS' ||
        activeSection === 'MENU_JOB_OPENINGS',
    },
  };

  const regionsStats = useRegionsStatsQuery(options)?.data;

  const neighbourCountriesCoordinates =
    useNeighbourCountriesWithCoordinatesQuery()?.data?.regions?.map(
      (region) => {
        return {
          type: 'Feature',
          geometry: {
            type: jsonParse(region.geoJsonGeometry).type,
            coordinates: jsonParse(region.geoJsonGeometry).coordinates,
          },
        };
      }
    );

  const regionsCoordinatesAndStats =
    useRegionsCoordinatesQuery()?.data?.regions?.map((region) => {
      return {
        properties: { name: region.name, id: region.id },
        tunisiaPopulation:
          regionsStats &&
          tunisiaPopulation(regionsStats?.totalPopulation)?.overall,
        population:
          regionsStats &&
          findRegionByRegionID(regionsStats?.totalPopulation, region)?.overall,
        scenarioOverall:
          regionsStats &&
          findRegionOverall(
            regionsStats,
            region,
            employmentsMapSection.id,
            activeSection
          ),
        type: 'Feature',
        id: region.name,
        geometry: {
          type: jsonParse(region.geoJsonGeometry).type,
          coordinates: jsonParse(region.geoJsonGeometry).coordinates,
        },
      };
    });

  const regionsCoordinatesAndStatsMatchingGaps =
    useRegionsCoordinatesQuery()?.data?.regions?.map((region) => {
      return {
        properties: { name: region.name, id: region.id },
        population:
          regionsStats &&
          findRegionByRegionID(regionsStats?.totalPopulation, region)?.overall,
        scenarioOverallX:
          regionsStats &&
          findRegionOverall(
            regionsStats,
            region,
            employmentsMatchingTheGaps.id,
            activeSection
          ),
        scenarioOverallY:
          regionsStats &&
          activeSection === 'MENU_MATCHING_GAPS' &&
          regionsStats['Job_Openings'] &&
          findRegionByRegionID(regionsStats['Job_Openings']?.values, region)
            ?.overall?.value,
        type: 'Feature',
        id: region.name,
        geometry: {
          type: jsonParse(region.geoJsonGeometry).type,
          coordinates: jsonParse(region.geoJsonGeometry).coordinates,
        },
      };
    });

  const mapInteractions = (_, layer) => {
    layer.on({
      mouseover: zoomedToRegion ? undefined : highlightFeature,
      mouseout: zoomedToRegion ? undefined : resetHighlight,
      click: zoomToRegion,
    });
  };

  const highlightFeature = ({ target }) => setTooltipInfo(target);

  const resetHighlight = () => setTooltipInfo(null);

  const zoomToRegion = ({ target }) => {
    dispatch({
      type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
      payload: {
        type: 'activeRegion',
        name: target.feature.properties.name,
        id: target.feature.properties.id,
      },
    });
    setFirstRender(false);
    setZoomedToRegion(true);
    mapRef?.target.flyToBounds(target.getBounds());
    geoJsonRef?.current?.setStyle({
      fillOpacity: 0.3,
    });
    target.setStyle({
      fillOpacity: 1,
      weight: 2,
    });
  };

  const applyMapStyle = ({ target  }) => {
    setFirstRender(false);
    setZoomedToRegion(true);
    mapRef?.target.flyToBounds(target.getBounds());
    geoJsonRef?.current?.setStyle({
      fillOpacity: 0.3,
    });
    target.setStyle({
      fillOpacity: 1,
      weight: 2,
    });
  }


  const handleBorderColors = () => {
    return {
      color:
        activeSection === 'MENU_JOB_OPENINGS'
          ? theme`colors.green.300`
          : employmentsMapSection.name === 'GLOBAL_INACTIVE'
          ? theme`colors.ochre.300`
          : employmentsMapSection.name === 'GLOBAL_UNEMPLOYED'
          ? theme`colors.orange.300`
          : theme`colors.blue.300`,
      fillColor:
        activeSection === 'MENU_JOB_OPENINGS'
          ? theme`colors.jobs`
          : activeSection === 'MENU_WORKFORCE' &&
            employmentsMapSection.name === 'GLOBAL_EMPLOYED'
          ? theme`colors.employed`
          : activeSection === 'MENU_MATCHING_GAPS' &&
            (employmentsMapSection.name === 'GLOBAL_INACTIVE' ||
              employmentsMatchingTheGaps.name === 'GLOBAL_INACTIVE')
          ? // theme`colors.GLOBAL_INACTIVE`
            theme`colors.ochre.300`
          : '#FFFAF4',
      fillOpacity: 1,
      weight: 1,
    };
  };

  return (
    !isLoadingNewMapRegionsData && (
      <MapContainer
        whenReady={setMapRef}
        center={centerMap}
        zoom={6.5}
        tw="w-full h-full"
        zoomControl={false}
        doubleClickZoom={false}
        dragging={false}
        zoomSnap={false}
        zoomDelta={false}
        trackResize={false}
        touchZoom={false}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://api.maptiler.com/tiles/countries/{z}/{x}/{y}.pbf?key=DiHgUrEIPkSg5Uc5v8ZA"
          attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a>'
        />
        {regionsCoordinatesAndStats?.every(
          ({ scenarioOverall }) => scenarioOverall
        ) ? (
          <>
            <GeoJSON
              data={
                activeSection === 'MENU_MATCHING_GAPS'
                  ? regionsCoordinatesAndStatsMatchingGaps
                  : regionsCoordinatesAndStats
              }
              ref={geoJsonRef}
              style={(region) =>
                regionStyle(region, employmentsMapSection.name, activeSection)
              }
              onEachFeature={mapInteractions}
            />
            <GeoJSON
              data={neighbourCountriesCoordinates}
              style={handleBorderColors}
            />
          </>
        ) : (
          <Loader message={t("LOADER_MAP_COORDINATES_MESSAGE")} />
        )}
        {!zoomedToRegion && (
          <TooltipContainer
            tooltipInfo={tooltipInfo}
            employmentsMapSection={employmentsMapSection.name}
            employmentsMatchingTheGaps={employmentsMatchingTheGaps.name}
            activeSection={activeSection}
          />
        )}
        {zoomedToRegion && (
          <button
            tw="z-[999] absolute left-0 rounded-[50%] bg-white p-[0.45rem] shadow ml-[0.5rem] top-[1rem]"
            onClick={resetZoom}
          >
            <img alt="Back button" src={back} width={18} />
          </button>
        )}
      </MapContainer>
    )
  );
};

export default Map;

import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import Loader from '../../../../../../Loader/Loader';
import useToggle from '../../../../../../../custom-hooks/useToggle';
import { findSelectedRegion } from '../../../../../../../utils/common';
import DynamicSvg from '../../../../../../DynamicSvg/DynamicSvg';
import arrow from '../../../../../../../assets/images/common/arrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const SubSectorsOpenJobs = ({ data }) => {
  const [
    { activeSector, activeSection, activeSubSector, activeRegion },
    dispatch,
  ] = useContext(TunisiaClockContext);
  const [{ subSectorsToggle }, handleToggleState] = useToggle();

  const findInData = (data) =>
    findSelectedRegion(data?.values, activeRegion.id)?.superSectors?.find(
      ({ sector }) => sector?.name === activeSector
    );

  const checkSubSectorsLength = (data) =>
    findInData(data)?.subSectors?.length > 1;

  const finalData = (data) =>
    findInData(data).subSectors?.find(
      ({ sector }) => sector?.name === activeSubSector
    );

  const groupedJobs = (data) =>
    data?.values?.flatMap((item) => {
      let groupByJobs = [];
      const selectedSuperSector = item.superSectors.find(
        ({ sector }) => sector.name === activeSector
      );
      let selectedSubSector = selectedSuperSector.subSectors.find(
        ({ sector }) => sector.name === activeSubSector
      );
      selectedSubSector = {
        ...selectedSubSector,
        region: {
          name: item.region.name,
        },
      };
      groupByJobs.push(selectedSubSector);
      return groupByJobs;
    });

  return (
    <article tw="pl-[0.75rem] w-full relative">
      {!activeSubSector ? (
        <Loader message={t("LOADER_SUBSECTORS_MESSAGE")} />
      ) : (
        <>
          <h4 tw="text-gray-500 font-semiBold text-xs">
            {t('SECTORS_SUBSECTORS')}
          </h4>
          <div tw="flex justify-between pt-[0.4rem] pb-[1rem]">
            <li
              tw="hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex items-center p-[0.625rem] justify-between w-full h-[3.93rem]"
              {...(checkSubSectorsLength(data) && {
                onClick: () =>
                  handleToggleState(subSectorsToggle, 'subSectorsToggle'),
              })}
            >
              {activeSubSector}
              {checkSubSectorsLength(data) && (
                <img
                  css={[
                    tw`ml-[1rem] transition duration-500`,
                    subSectorsToggle && tw`rotate-180`,
                  ]}
                  alt="Arrow"
                  width={14}
                  src={arrow}
                />
              )}
              {subSectorsToggle && (
                <ul
                  tw="text-gray-800 text-xs absolute top-[4rem] left-0 w-full cursor-pointer shadow bg-white max-h-[11.3rem] overflow-y-scroll text-left z-[1]"
                  className="scrollable scrollableRegions"
                >
                  {findInData(data)
                    .subSectors.filter(
                      ({ sector }) => sector.name !== activeSubSector
                    )
                    .map(({ sector }) => (
                      <li
                        tw="p-[1rem] border-b border-b-gray-100"
                        id={sector?.name}
                        key={sector?.name}
                        onClick={() =>
                          dispatch({
                            type: 'SET_ACTIVE_FILTER',
                            payload: {
                              type: 'activeSubSector',
                              name: sector?.name,
                            },
                          })
                        }
                      >
                        <span tw="text-sm text-gray-800">{sector?.name}</span>
                      </li>
                    ))}
                </ul>
              )}
            </li>
            <span tw="flex items-center gap-[0.5rem] justify-between pl-[1.438rem]">
              <p
                css={[
                  tw`font-medium text-blue-400`,
                  activeSection === 'MENU_JOB_OPENINGS' && tw`text-green-400`,
                ]}
              >
                {finalData(data)?.overall?.value.toLocaleString()}
              </p>
              <DynamicSvg
                iconName={finalData(data)?.overall?.direction}
                size={12}
              />
            </span>
          </div>
          <div tw="flex items-center justify-between text-gray-500 text-xs font-semiBold border-b border-b-gray-100 p-[0 0.5rem 0.5rem]">
            <span>{t('GLOBAL_REGION')}</span>
            <span>{t('GLOBAL_OPEN_JOBS')}</span>
          </div>
          <div className="scrollable" tw="h-[7rem] overflow-y-scroll">
            {groupedJobs(data)?.map(({ overall, region }) => (
              <div
                key={overall.value + region.name}
                tw="flex justify-between items-center p-[0.20rem 0.5rem] even-of-type:bg-tableGreen"
              >
                <span tw="text-gray-800">{region?.name}</span>
                <span tw="flex items-center gap-[0.5rem] justify-between pl-[1.438rem]">
                  <p tw="text-green-500 font-medium text-sm line-height[1.026rem]">
                    {overall?.value.toLocaleString()}
                  </p>
                  <DynamicSvg iconName={overall?.direction} size={10} />
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </article>
  );
};

export default SubSectorsOpenJobs;

import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import Groups from './Groups/Groups';
import AgeAndGender from './AgeAndGender/AgeAndGender';
import FlipArrow from '../../../../../../../assets/images/hero_section/flipArrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const GroupsAgeGender = ({ data }) => {
  const [{ showBreakdownBar, activeReason }, dispatch] =
    useContext(TunisiaClockContext);

  return (
    <div tw="w-full mb-[1rem] rounded-[1.25rem] border border-gray-100 shadow p-[0.75rem 1rem] cursor-pointer">
      <div
        tw="flex justify-between"
        onClick={() =>
          dispatch({
            type: 'TOGGLE_FILTER_AND_BREAKDOWN_BAR',
            payload: {
              type: 'showBreakdownBar',
              showBreakdownBar: showBreakdownBar,
            },
          })
        }
      >
        <h3 tw="text-blue-400 text-xs font-medium">
          {t('FILTERS_AGE_GENDER_GROUP')}
        </h3>
        <img
          css={[
            tw`transition duration-500`,
            showBreakdownBar && tw`rotate-180`,
          ]}
          alt="Flip arrow"
          src={FlipArrow}
        />
      </div>
      {showBreakdownBar && data && (
        <div tw="flex pt-[1rem] text-[0.875rem]">
          <Groups data={data} />
          {activeReason && <AgeAndGender data={data} />}
        </div>
      )}
    </div>
  );
};

export default GroupsAgeGender;

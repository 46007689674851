import tw, { css, styled, theme } from 'twin.macro';

export const CheckBoxLabel = styled.label([
  tw`absolute top-0 left-0 w-[2rem] h-[1rem] rounded-[2.125rem] bg-gray-200 cursor-pointer`,
  css`
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 0.875rem;
      height: 0.875rem;
      margin: 1px;
      background: ${theme`colors.white`};
      transition: 0.2s;
    }
  `,
]);

export const CheckBox = styled.input([
  tw`w-[2rem] h-[0.75rem]`,
  css`
    &:checked + label {
      background: ${theme`colors.blue.350`};
      transition: 0.2s;

      &::after {
        content: '';
        display: block;
        margin-left: 1.06rem;
      }
    }
  `,
]);

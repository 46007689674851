import { Grid, Col, Container } from '../../assets/styles/grid';
import FooterSignature from '../../components/Footer/FooterSignature';
import FooterSocials from '../../components/Footer/FooterSocials';
import FooterGiz from '../../components/Footer/FooterGiz';
import HeroSection from '../../components/Main/HeroSection/HeroSection';
import MapSection from '../../components/Main/MapSection/MapSection';
import FormContainer from '../../components/Form/FormContainer';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const TunisiaClock = () => (
  <>
    <Container as="main" tw="relative z-[0] m-auto overflow-hidden">
      <HeroSection />
      <MapSection />
    </Container>
    <Container as="footer" tw="text-white bg-blue-400">
      <Grid cols={12}>
        <Col col={4} lg={6} md={12}>
          <FooterSignature />
        </Col>
        <Col col={5} lg={6} md={12}>
          <FooterSocials />
        </Col>
        <Col col={3} lg={12}>
          <FooterGiz />
        </Col>
      </Grid>
    </Container>
    <FormContainer />
  </>
);

export default TunisiaClock;

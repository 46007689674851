import { useState, useEffect } from 'react';

const useActivePage = () => {
  const [activePage, setActivePage] = useState('hero_section');

  const handleActivePage = () => {
    const observable = {
      hero_section: document.querySelector('#hero_section'),
      map_section: document.querySelector('#map_section'),
    };
    const pos = {
      hero_section: observable.hero_section.getBoundingClientRect(),
      map_section: observable.map_section.getBoundingClientRect(),
    };

    if (
      pos.hero_section.top < window.innerHeight &&
      pos.hero_section.bottom > 1
    )
      setActivePage('hero_section');
    else if (
      pos.map_section.top < window.innerHeight &&
      pos.map_section.bottom > 1
    )
      setActivePage('map_section');
  };

  useEffect(() => {
    window.addEventListener('scroll', handleActivePage);
    return () => window.removeEventListener('scroll', handleActivePage);
  }, []);

  return activePage;
};

export default useActivePage;

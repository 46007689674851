import { useState } from 'react';

const useForm = (initialState = {}, onValidated) => {
  const [formData, setFormData] = useState(initialState);

  const handleInputChange = ({ name, value, type, checked }) =>
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

  const handleReset = () => setFormData({});

  const handleSubmit = (e) => {
    e.preventDefault();
    onValidated({
      firstName: formData.firstName,
      lastName: formData.lastName,
      companyName: formData.companyName,
      companyEmail: formData.companyEmail,
      message: formData.message,
      joinMailingList: formData.joinMailingList,
    });
  };

  return { formData, handleInputChange, handleReset, handleSubmit };
};

export default useForm;

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { urls } from "./urls.js";
import cacheVersion from "./cache_version.json"
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// GlobalStyles imported globally to make specific classes to work together with twin.macro
import { GlobalStyles } from "twin.macro";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getI18n } from "react-i18next";
const container = document.getElementById("root");
const root = createRoot(container);

const customFetch = (_, options) =>
    fetch(`${urls.API_HOST_URL}?cache_version=${cacheVersion}`, options);

const apolloHttpLink = createHttpLink({
    fetch: customFetch
});

const apolloLangContext = setContext(async (_, { headers }) => {
    const lang = localStorage.getItem("i18nextLng");
    return {
        headers: {
            ...headers,
            lang
        }
    };
});

const i18nContext = getI18n();
const apolloClient = new ApolloClient({
    uri: urls.API_HOST_URL,
    cache: new InMemoryCache(),
    link: apolloLangContext.concat(apolloHttpLink)
});

root.render(
    <StrictMode>
        <ApolloProvider client={apolloClient}>
            <App />
            <GlobalStyles />
        </ApolloProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const links = {
  employmentsHeroSection: [
    {
      id: 'Employed',
      name: 'GLOBAL_EMPLOYED',
      description: 'HEADER_EMPLOYED_DESC',
    },
    {
      id: 'Inactive',
      description: 'GLOBAL_INACTIVE_DESC',
      name: 'GLOBAL_INACTIVE',
      popup: 'GLOBAL_INACTIVE_DESC',
    },
    {
      id: 'Unemployed',
      name: 'GLOBAL_UNEMPLOYED',
      description: 'GLOBAL_UNEMPLOYED_DESC_SHORT',
      popup: 'GLOBAL_UNEMPLOYED_DESC',
    },
  ],
  employmentsMapSection: [
    {
      id: 'Employed',
      name: 'GLOBAL_EMPLOYED',
      description: 'HEADER_EMPLOYED_DESC',
      popup: 'GLOBAL_EMPLOYED_DESC_LONG',
    },
    {
      id: 'Inactive',
      name: 'GLOBAL_INACTIVE',
      description: 'GLOBAL_INACTIVE_DESC',
      popup: 'GLOBAL_INACTIVE_DESC_LONG',
    },
    {
      id: 'Unemployed',
      name: 'GLOBAL_UNEMPLOYED',
      description: 'GLOBAL_UNEMPLOYED_DESC_SHORT',
      popup: 'GLOBAL_UNEMPLOYED_DESC',
    },
    {
      id: 'inactiveAndUnemployed',
      name: 'GLOBAL_INACTIVE_UNEMPLOYED',
      description: ' ',
      popup: 'GLOBAL_INACTIVE_DESC_LONG',
    },
  ],
  employmentsMatchingTheGaps: [
    {
      id: 'Inactive',
      name: 'GLOBAL_INACTIVE',
      description:
        'Population that is not involved in the labour market - they are neither working or actively seeking employment',
      popup:
        'Population that is not involved in the labour market - they are neither working or actively seeking employment. Economic inactivity includes students, early retiree and the long-term sick',
    },
    {
      id: 'Unemployed',
      name: 'GLOBAL_UNEMPLOYED',
      description: 'Population that is unable to get a job',
      popup:
        'Population that is not able to get a job but would like to be in full-time employment',
    },
    {
      id: 'inactiveAndUnemployed',
      name: 'GLOBAL_INACTIVE_UNEMPLOYED',
      description: ' ',
      popup: 'GLOBAL_INACTIVE_DESC_LONG',
    },
  ],
  genders: [
    { id: 'All genders', name: 'GLOBAL_ALL_GENDERS' },
    { id: 'Females', name: 'GLOBAL_FEMALES' },
    { id: 'Males', name: 'GLOBAL_MALES' },
  ],
  ages: [
    { id: 'All', name: 'GLOBAL_ALL_AGES' },
    {
      id: 'FifteenToTwentyfour',
      name: '15 - 24',
    },
    {
      id: 'TwentyfiveToThirtyfour',
      name: '25 - 34',
    },
    {
      id: 'ThirtyfiveToFourtyfour',
      name: '35 - 44',
    },
    {
      id: 'FourtyfiveToFivtyfour',
      name: '45 - 54',
    },
    {
      id: 'FivtyfiveToSixtyfour',
      name: '55 - 64',
    },
  ],
  activeRegion: [
    {
      id: 'Tunisia',
      name: 'Tunisia',
    },
    {
      id: 'Ariana',
      name: 'Ariana',
    },
    {
      id: 'Ben Arous (Tunis Sud)',
      name: 'Ben Arous (Tunis Sud)',
    },
    {
      id: 'Bizerte',
      name: 'Bizerte',
    },
    {
      id: 'Béja',
      name: 'Béja',
    },
    {
      id: 'Gabès',
      name: 'Gabès',
    },
    {
      id: 'Gafsa',
      name: 'Gafsa',
    },
    {
      id: 'Jendouba',
      name: 'Jendouba',
    },
    {
      id: 'Kairouan',
      name: 'Kairouan',
    },
    {
      id: 'Kassérine',
      name: 'Kassérine',
    },
    {
      id: 'Kebili',
      name: 'Kebili',
    },
    {
      id: 'Le Kef',
      name: 'Le Kef',
    },
    {
      id: 'Mahdia',
      name: 'Mahdia',
    },
    {
      id: 'Manubah',
      name: 'Manubah',
    },
    {
      id: 'Monastir',
      name: 'Monastir',
    },
    {
      id: 'Médenine',
      name: 'Médenine',
    },
    {
      id: 'Nabeul',
      name: 'Nabeul',
    },
    {
      id: 'Sfax',
      name: 'Sfax',
    },
    {
      id: 'Sidi Bou Zid',
      name: 'Sidi Bou Zid',
    },
    {
      id: 'Siliana',
      name: 'Siliana',
    },
    {
      id: 'Sousse',
      name: 'Sousse',
    },
    {
      id: 'Tataouine',
      name: 'Tataouine',
    },
    {
      id: 'Tozeur',
      name: 'Tozeur',
    },
    {
      id: 'Tunis',
      name: 'Tunis',
    },
    {
      id: 'Zaghouan',
      name: 'Zaghouan',
    },
  ],
  overview: [
    {
      id: 'MENU_WORKFORCE',
      name: 'MENU_WORKFORCE',
    },
    {
      id: 'JobOpenings',
      name: 'MENU_JOB_OPENINGS',
    },
    {
      id: 'MatchingTheGaps',
      name: 'MENU_MATCHING_GAPS',
    },
  ],
  mapLegendValues: [
    {
      employedValue: '> 40',
      inactiveValue: '> 70',
      unemployedValue: '> 11',
      unemployedInactiveValue: '> 80',
      jobsValue: '> 1.5',
      GLOBAL_EMPLOYED: '#014B9C',
      GLOBAL_INACTIVE: '#E8AE45',
      GLOBAL_UNEMPLOYED: '#FF6600',
      Jobs: '#3B8D73',
    },
    {
      employedValue: '40-30',
      inactiveValue: '70-65',
      unemployedValue: '11-9',
      unemployedInactiveValue: '80-75',
      jobsValue: '1.5-1.2',
      GLOBAL_EMPLOYED: '#3A7CC5',
      GLOBAL_INACTIVE: '#F8BF56',
      GLOBAL_UNEMPLOYED: '#FEA41D',
      Jobs: '#47A386',
    },
    {
      employedValue: '30-20',
      inactiveValue: '65-60',
      unemployedValue: '9-7',
      unemployedInactiveValue: '75-70',
      jobsValue: '1.2-1',
      GLOBAL_EMPLOYED: '#9ACDF2',
      GLOBAL_INACTIVE: '#F8D79A',
      GLOBAL_UNEMPLOYED: '#FFD18C',
      Jobs: '#7FB3A2',
    },
    {
      employedValue: '20-10',
      inactiveValue: '60-55',
      unemployedValue: '7-5',
      unemployedInactiveValue: '70-65',
      jobsValue: '1-0.75',
      GLOBAL_EMPLOYED: '#C2E4FC',
      GLOBAL_INACTIVE: '#FFEAC5',
      GLOBAL_UNEMPLOYED: '#FFE4BB',
      Jobs: '#92CCB9',
    },
    {
      employedValue: '10-0',
      inactiveValue: '55-0',
      unemployedValue: '5-0',
      unemployedInactiveValue: '65-0',
      jobsValue: '0.75-0',
      GLOBAL_EMPLOYED: '#DEF1FF',
      GLOBAL_INACTIVE: '#FFF0D7',
      GLOBAL_UNEMPLOYED: '#FCF3E7',
      Jobs: '#A6E2CF',
    },
  ],
};

import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import DynamicSvg from '../../../../../../../DynamicSvg/DynamicSvg';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';

const AgeAndGender = ({ data }) => {
  const [{ activeReason, ages, genders }] = useContext(TunisiaClockContext);

  const finalData = (data) =>
    data?.find(({ reason }) => reason.name === activeReason);

  return (
    <article tw="pl-[0.75rem] w-full">
      <div tw="flex items-center justify-between text-gray-500 font-semiBold border-b border-b-gray-100 p-[0 0.5rem 0.5rem]">
        <span>{t('GLOBAL_AGE')}</span>
        <DynamicSvg
          iconName="MaleGender"
          size={16}
          fill={theme`colors.gray.500`}
        />
        <DynamicSvg
          iconName="FemaleGender"
          size={16}
          fill={theme`colors.gray.500`}
        />
      </div>
      {finalData(data)?.ageGenderValues.map(({ ageGroup, females, males }) => (
        <div
          key={ageGroup?.from}
          tw="flex justify-between items-center p-[0.55rem 0.5rem] even-of-type:bg-tableInactive"
        >
          <span
            css={[
              tw`text-gray-800 text-sm w-[20%]`,
              `${ageGroup.from} - ${ageGroup.to}` === ages.name ||
              ages.id === 'All'
                ? tw`opacity-100`
                : tw`opacity-25`,
            ]}
          >
            {`${ageGroup?.from} - ${ageGroup?.to}`}
          </span>
          <span
            css={[
              tw`font-medium text-sm flex gap-[0.5rem] items-center text-ochre-500 flex-1 justify-end`,
              (genders.id === 'Males' || genders.id === 'All genders') &&
              (`${ageGroup.from} - ${ageGroup.to}` === ages.name ||
                ages.id === 'All')
                ? tw`opacity-100`
                : tw`opacity-25`,
            ]}
          >
            {males?.value.toLocaleString()}
            <DynamicSvg iconName={males?.direction} size={10} />
          </span>
          <span
            css={[
              tw`font-medium text-sm flex gap-[0.5rem] items-center text-ochre-500 flex-1 justify-end`,
              (genders.id === 'Females' || genders.id === 'All genders') &&
              (`${ageGroup.from} - ${ageGroup.to}` === ages.name ||
                ages.id === 'All')
                ? tw`opacity-100`
                : tw`opacity-25`,
            ]}
          >
            {females?.value.toLocaleString()}
            <DynamicSvg iconName={females?.direction} size={10} />
          </span>
        </div>
      ))}
      <div tw="flex justify-between items-center p-[0.20rem 0.5rem] even-of-type:bg-tableBlue">
        <span
          css={[
            tw`text-gray-800 text-sm w-[20%]`,
            ages.id === 'All' ? tw`opacity-100` : tw`opacity-25`,
          ]}
        >
          Total
        </span>
        <span
          css={[
            tw`font-medium text-sm flex gap-[0.5rem] items-center  text-ochre-500 flex-1 justify-end`,
            (genders.id === 'Males' || genders.id === 'All genders') &&
            ages.id === 'All'
              ? tw`opacity-100`
              : tw`opacity-25`,
          ]}
        >
          {finalData(data)?.males?.value.toLocaleString()}
          <DynamicSvg iconName={finalData(data)?.males?.direction} size={10} />
        </span>
        <span
          css={[
            tw`font-medium text-sm flex gap-[0.5rem] items-center  text-ochre-500 flex-1 justify-end`,
            (genders.id === 'Females' || genders.id === 'All genders') &&
            ages.id === 'All'
              ? tw`opacity-100`
              : tw`opacity-25`,
          ]}
        >
          {finalData(data)?.females?.value.toLocaleString()}
          <DynamicSvg
            iconName={finalData(data)?.females?.direction}
            size={10}
          />
        </span>
      </div>
    </article>
  );
};

export default AgeAndGender;

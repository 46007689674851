import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import SubSectorsOpenJobs from './SubSectorsOpenJobs/SubSectorsOpenJobs';
import SubSectorsOpenJobsRegion from './SubSectorsOpenJobsRegion/SubSectorsOpenJobsRegion';
import FlipArrow from '../../../../../../assets/images/hero_section/flipArrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { t } from 'i18next';

const RegionsAndSectorsJobs = ({ matchingGapJobs }) => {
  const [{ showMatchingTheGapsJobs, activeRegion }, dispatch] =
    useContext(TunisiaClockContext);

  return (
    <div tw="w-full mb-[1rem] rounded-[1.25rem] border border-gray-100 shadow p-[0.75rem 1rem] cursor-pointer">
      <div
        tw="flex justify-between"
        onClick={() =>
          dispatch({
            type: 'TOGGLE_FILTER_AND_BREAKDOWN_BAR',
            payload: {
              type: 'showMatchingTheGapsJobs',
              showMatchingTheGapsJobs: showMatchingTheGapsJobs,
            },
          })
        }
      >
        <h3 tw="text-blue-400 text-xs font-medium">
          {t('GLOBAL_SECTORS_BREAKDOWN')}
        </h3>
        <img
          css={[
            tw`transition duration-500`,
            showMatchingTheGapsJobs && tw`rotate-180`,
          ]}
          alt="Flip arrow"
          src={FlipArrow}
        />
      </div>
      {showMatchingTheGapsJobs && matchingGapJobs && (
        <div tw="flex pt-[1rem] text-[0.875rem]">
          {activeRegion.id === 'Tunisia' ? (
            <SubSectorsOpenJobs matchingGapJobs={matchingGapJobs} />
          ) : (
            <SubSectorsOpenJobsRegion matchingGapJobs={matchingGapJobs} />
          )}
        </div>
      )}
    </div>
  );
};

export default RegionsAndSectorsJobs;

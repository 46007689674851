import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import FlipNumbers from 'react-flip-numbers';
import DynamicSvg from '../../../../../DynamicSvg/DynamicSvg';
import Insight from '../Insight/Insight';
import Loader from '../../../../../Loader/Loader';
import useToggle from '../../../../../../custom-hooks/useToggle';
import Slider from '../../../../../SliderRange/Slider';
import AgeAndGenderInactive from './AgeAndGender/AgeAndGenderInactive';
import AgeAndGenderUnemployed from './AgeAndGender/AgeAndGenderUnemployed';
import {
  removeActiveItemFromList,
  flipNumbersStyles,
  overallValueAndDirection,
  percentageOfWorkingAgePopulation,
  sortTunisiaRegions,
  togglePopup,
} from '../../../../../../utils/common';
import arrow from '../../../../../../assets/images/common/arrow.svg';
import FilterIcon from '../../../../../../assets/images/map_section/filterIcon.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import Popup from '../../../../../Popup/Popup';
import CustomSlider from '../../../../../SliderRange/Slider';

const InactiveAndUnemployed = ({ data, tunisiaRegions, insightsData }) => {
  const [state, dispatch] = useContext(TunisiaClockContext);
  const [
    { employmentsToggle, regionsToggle, insightsToggle },
    handleToggleState,
  ] = useToggle();

  return (
    <article tw="flex justify-between flex-col pr-[11.3125rem] max-w-[51rem] ml-auto w-full z-[1]">
      <div>
        <div>
          <div tw="flex justify-between items-center opacity-100 transition duration-500 relative">
            <ul tw="flex items-center max-w-[12rem] mt-[2rem]">
              <li
                tw="hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem] mr-[0.625rem] justify-between"
                onClick={() =>
                  handleToggleState(regionsToggle, 'regionsToggle')
                }
              >
                {state.activeRegion.id === 'Tunisia'
                  ? t('GLOBAL_TUNISIA')
                  : state.activeRegion.name}
                <img
                  css={[
                    tw`ml-[1rem] transition duration-500`,
                    regionsToggle && tw`rotate-180`,
                  ]}
                  alt="Arrow"
                  width={14}
                  src={arrow}
                />
                {regionsToggle && (
                  <ul
                    tw="text-gray-800 text-xs absolute top-[2.9rem] left-0 w-[8rem] cursor-pointer shadow bg-white max-h-[21rem] overflow-y-scroll text-left z-[2]"
                    className="scrollable scrollableRegions"
                  >
                    {sortTunisiaRegions(
                      tunisiaRegions,
                      state.activeRegion.id,
                      dispatch
                    )}
                  </ul>
                )}
              </li>
              <li>{t('HEADER_REGION_HAS')}</li>
              <button
                css={[
                  tw`flex justify-evenly items-center p-[0.56rem 1rem] gap-[0.25rem] h-[2.215rem] w-[8.43rem] border border-blue-400 text-gray-800 text-[0.875rem] rounded-[3.37rem] absolute right-0 top-[1rem]`,
                  state.showFiltersBar && tw`opacity-0`,
                ]}
                onClick={() =>
                  dispatch({
                    type: 'TOGGLE_FILTER_AND_BREAKDOWN_BAR',
                    payload: {
                      type: 'showFiltersBar',
                      showFiltersBar: state.showFiltersBar,
                    },
                  })
                }
              >
                <img alt="Filter icon" src={FilterIcon} width={16} />
                <span>{t('MAP_FILTERS')}</span>
              </button>
            </ul>
          </div>
          <div tw="flex items-center p-[0.5rem 0 1rem]">
            <span tw="font-semiBold mr-[0.625rem] h-[3rem] flex relative min-w-[5rem] text-orange-500">
              {!data['GLOBAL_INACTIVE_UNEMPLOYED'] ? (
                <Loader message="" />
              ) : (
                <FlipNumbers
                  duration={1}
                  play
                  numbers={overallValueAndDirection(
                    state.ages,
                    state.genders.id,
                    data['GLOBAL_INACTIVE_UNEMPLOYED']
                  )?.value?.toLocaleString()}
                  width={35}
                  height={80}
                  numberStyle={flipNumbersStyles}
                  nonNumberStyle={flipNumbersStyles}
                  perspective={100000}
                />
              )}
            </span>
            <DynamicSvg
              iconName={
                overallValueAndDirection(
                  state.ages,
                  state.genders.id,
                  data['GLOBAL_INACTIVE_UNEMPLOYED']
                )?.direction
              }
              size={24}
            />
            <ul tw="flex items-center ml-[0.625rem] mr-[0.625rem]">
              <li
                css={[tw`hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem 0.625rem 1rem] justify-between max-w-[10rem]`,
                togglePopup()
                ]}
                onClick={() =>
                  handleToggleState(employmentsToggle, 'employmentsToggle')
                }
              >
                {t(state.employmentsMapSection.name).toLowerCase()}
                <img
                  css={[
                    tw`ml-[1rem] transition duration-500`,
                    employmentsToggle && tw`rotate-180`,
                  ]}
                  alt="Arrow"
                  width={14}
                  src={arrow}
                />
                {!employmentsToggle && (
                  <Popup
                    message={t('GLOBAL_INACTIVE_DESC_LONG')}
                    isFromEmploymentsMap={true}
                  />
                )}
                {employmentsToggle && (
                  <ul tw="text-gray-800 text-xs absolute top-[2.9rem] left-0 cursor-pointer shadow bg-white w-[17rem] z-[2]">
                    {removeActiveItemFromList(
                      'employmentsMapSection',
                      state,
                      dispatch,
                      t
                    )}
                  </ul>
                )}
              </li>
              <li tw="ml-[0.625rem]">
                {state.genders.id === 'All genders'
                  ? `${t(`${'GLOBAL_ALL_GENDERS'}`).toLowerCase()}`
                  : `${t(`${state.genders.name}`).toLowerCase()}`}
              </li>
            </ul>
          </div>
        </div>
        <div tw="text-regular pb-[0.75rem] flex">
          {isNaN(
            percentageOfWorkingAgePopulation(
              state.ages,
              state.genders.id,
              data['GLOBAL_INACTIVE_UNEMPLOYED']
            )
          ) ? (
            <span tw="relative w-[3rem]">
              <Loader message="" />
            </span>
          ) : (
            <span tw="pr-[0.25rem] text-orange-500">
              {percentageOfWorkingAgePopulation(
                state.ages,
                state.genders.id,
                data['GLOBAL_INACTIVE_UNEMPLOYED']
              )}
              %
            </span>
          )}
          <span> {t('HEADER_TUNISIA_PERCENT_OF')}</span>
        </div>
        <div
          tw="p-[0.75rem 1rem] rounded-[1.25rem] border border-gray-100 shadow cursor-pointer"
          onClick={() =>
            dispatch({
              type: 'TOGGLE_FILTER_AND_BREAKDOWN_BAR',
              payload: {
                type: 'showBreakdownBar',
                showBreakdownBar: state.showBreakdownBar,
              },
            })
          }
        >
          <div tw="flex items-center justify-between">
            <h3 tw="text-blue-400 text-xs font-medium">
              {t('GLOBAL_FILTERS_BREAKDOWN')}
            </h3>
            <img
              css={[
                tw`transition duration-500`,
                state.showBreakdownBar && tw`rotate-180`,
              ]}
              alt="Arrow"
              src={arrow}
            />
          </div>
          <div tw="flex gap-[1rem]">
            {data && state.showBreakdownBar && (
              <>
                <AgeAndGenderInactive
                  data={data.GLOBAL_INACTIVE}
                  type="GLOBAL_INACTIVE"
                />
                <div tw="h-[12.5rem] border border-gray-100"></div>
                <AgeAndGenderUnemployed
                  data={data.GLOBAL_UNEMPLOYED}
                  type="GLOBAL_UNEMPLOYED"
                />
              </>
            )}
          </div>
        </div>
        <div tw="w-full my-[1rem] rounded-[1.25rem] border border-gray-100 shadow p-[0.75rem 1rem] cursor-pointer">
          <div
            tw="flex justify-between"
            onClick={() => handleToggleState(insightsToggle, 'insightsToggle')}
          >
            <h3 tw="text-blue-400 text-xs font-medium">
              {t('FILTERS_INSIGHTS')}
            </h3>
            <img
              css={[
                tw`transition duration-500`,
                insightsToggle && tw`rotate-180`,
              ]}
              alt="Flip arrow"
              src={arrow}
            />
          </div>
          {insightsToggle && insightsData && (
            <Insight
              employments={state.employments}
              insightsData={insightsData}
            />
          )}
        </div>
      </div>
      <CustomSlider isFromMapSection={true} />
    </article>
  );
};

export default InactiveAndUnemployed;

import { t } from 'i18next';
/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';

const Tooltip = ({
  tooltipInfo,
  position,
  employmentsMapSection,
  employmentsMatchingTheGaps,
  activeSection,
}) => (
  <div
    css={[
      tw`rounded-[0.5rem] shadow max-w-[18.75rem] bg-gray-500 fixed z-[1001] w-[12rem] text-white p-[1.2rem 1rem] gap-[1rem] flex flex-col`,
      css({ left: `${position.x + 20}px`, top: `${position.y - 60}px` }),
    ]}
  >
    <div tw="font-semiBold">
      <h4>{tooltipInfo.feature.id}</h4>
    </div>
    <div tw="border-b border-gray-400 flex justify-between items-center">
      <div>Population</div>
      <div tw="font-medium">
        {tooltipInfo.feature.population.toLocaleString()}
      </div>
    </div>
    {activeSection === 'MENU_WORKFORCE' && (
      <div tw="border-b border-gray-400 flex justify-between items-center">
        <div tw="max-w-[60px]">{t(employmentsMapSection)}</div>
        <div tw="font-medium">
          {tooltipInfo.feature.scenarioOverall.toLocaleString()}
        </div>
      </div>
    )}
    {activeSection === 'MENU_JOB_OPENINGS' && (
      <div tw="border-b border-gray-400 flex justify-between items-center">
        <div>{t('GLOBAL_OPEN_JOBS')}</div>
        <div tw="font-medium">
          {tooltipInfo.feature.scenarioOverall.toLocaleString()}
        </div>
      </div>
    )}
    {activeSection === 'MENU_MATCHING_GAPS' && (
      <div tw="flex flex-col border-b border-gray-400 gap-[1rem]">
        <div tw="flex justify-between items-center border-b border-gray-400">
          <div>{t(employmentsMatchingTheGaps)}</div>
          <div tw="font-medium">
            {tooltipInfo.feature.scenarioOverallX.toLocaleString()}
          </div>
        </div>
        <div tw="flex justify-between items-center">
          <div>{t('GLOBAL_OPEN_JOBS')}</div>
          <div tw="font-medium">
            {tooltipInfo.feature.scenarioOverallY.toLocaleString()}
          </div>
        </div>
      </div>
    )}
    <span tw="font-semiBold text-left">{t('MAP_CLICK_DETAILS')}</span>
  </div>
);

export default Tooltip;

export const TRIGGER_NAVBAR = 'navBar';
export const TRIGGER_DROPDOWN = 'dropdown';

export const initialState = {
  selectedYear: new Date().getFullYear(),
  employmentsHeroSection: { id: 'Employed', name: 'GLOBAL_EMPLOYED' },
  employmentsMapSection: { id: 'Employed', name: 'GLOBAL_EMPLOYED' },
  employmentsMatchingTheGaps: { id: 'Unemployed', name: 'GLOBAL_UNEMPLOYED' },
  genders: { id: 'All genders', name: 'GLOBAL_ALL_GENDERS' },
  ages: { id: 'All', name: 'GLOBAL_ALL_AGES' },
  activeRegion: { id: 'Tunisia', name: 'GLOBAL_TUNISIA' },
  reset: false,
  showModalForm: false,
  showFiltersBar: false,
  showBreakdownBar: false,
  showMatchingTheGapsRegions: false,
  showMatchingTheGapsJobs: false,
  isLoadingNewMapRegionsData: true,
  activeSection: 'MENU_WORKFORCE',
  activeSector: null,
  activeSubSector: null,
  activeReason: null,
  latestTrigger: TRIGGER_NAVBAR,
};

export const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'SET_ACTIVE_FILTER':
      return {
        ...state,
        [payload.type]:
          payload.type === 'activeSector' ||
          payload.type === 'activeSubSector' ||
          payload.type === 'activeReason' ||
          payload.type === 'selectedYear'
            ? payload.name
            : { id: payload.id, name: payload.name },
        reset:
          (payload.type === 'ages' ||
            payload.type === 'genders' ||
            payload.type === 'employmentsHeroSection' ||
            payload.type === 'employmentsMapSection') &&
          true,
      };
    case 'SET_LATEST_TRIGGER':
      return {
        ...state,
        latestTrigger: payload,
      };
    case 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION':
      return {
        ...state,
        [payload.type]:
          payload.type === 'activeRegion'
            ? { id: payload.id, name: payload.name }
            : payload.id || payload.name,
      };
    case 'TOGGLE_FILTER_AND_BREAKDOWN_BAR':
      return {
        ...state,
        [payload.type]: payload.showFiltersBar
          ? !payload.showFiltersBar
          : payload.showMatchingTheGapsRegions
          ? !payload.showMatchingTheGapsRegions
          : payload.showMatchingTheGapsJobs
          ? !payload.showMatchingTheGapsJobs
          : !payload.showBreakdownBar,
      };
    case 'SHOW_MODAL_FORM':
      return {
        ...state,
        showModalForm: !state.showModalForm,
      };
    case 'TOGGLE_LOADING_NEW_MAP_DATA':
      return {
        ...state,
        isLoadingNewMapRegionsData: payload,
      };
    case 'RESET_FILTERS':
      return {
        ...initialState,
        isLoadingNewMapRegionsData: state.isLoadingNewMapRegionsData,
        activeSector: state.activeSector,
        activeSubSector: state.activeSubSector,
        activeReason: state.activeReason,
      };
    default:
      return state;
  }
};

import { useContext, useEffect } from 'react';
import { TunisiaClockContext } from '../../../views/TunisiaClock/TunisiaClockContextProvider';
import {
  useEmployedRegionAndSectorAndSubsectorAndAgeAndGenderByYearQuery,
  useUnemployedRegionAndAgeAndGenderByYearQuery,
  useInactiveReasonsAndRegionAndAgeAndGenderByYearQuery,
  useOpenJobsBySectorAndRegionbyYearQuery,
  useInactiveAndUnemployedRegionAndAgeAndGenderByYearQuery,
  useInsightsQuery,
  useTunisiaRegionsQuery,
} from '../../../generated/graphql.d.ts';
import NavbarMapSection from '../../Header/NavbarMapSection';
import Workforce from './Workforce/Workforce';
import JobOpenings from './JobOpenings/JobOpenings';
import MatchingGap from './MatchingGap/MatchingGap';
import { findSelectedRegion, isDataLoading } from '../../../utils/common';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const MapSection = () => {
  const [
    {
      employmentsMapSection,
      employmentsMatchingTheGaps,
      activeSection,
      selectedYear,
      activeRegion,
      ages,
      gender,
    },
    dispatch,
  ] = useContext(TunisiaClockContext);

  const options = {
    variables: {
      year: selectedYear,
    },
  };

  const insightsOptions = {
    variables: {
      year: selectedYear,
      regionName: activeRegion.id,
      insightType:
        activeSection === 'MENU_JOB_OPENINGS'
          ? 'OpenJobs'
          : employmentsMapSection.name === 'GLOBAL_INACTIVE_UNEMPLOYED'
          ? 'InactiveAndUnemployed'
          : employmentsMapSection.name === 'GLOBAL_EMPLOYED'
          ? 'Employed'
          : employmentsMapSection.name === 'GLOBAL_UNEMPLOYED'
          ? 'Unemployed'
          : 'Inactive',
    },
  };

  const insightsData = useInsightsQuery({
    ...insightsOptions,
    skip: activeSection === 'MENU_MATCHING_GAPS',
  });

  const tunisiaRegions = useTunisiaRegionsQuery()?.data?.regions;

  let workforceData = {
    GLOBAL_EMPLOYED:
      useEmployedRegionAndSectorAndSubsectorAndAgeAndGenderByYearQuery({
        ...options,
        skip:
          activeSection === 'MENU_MATCHING_GAPS' ||
          activeSection === 'MENU_JOB_OPENINGS' ||
          employmentsMapSection.name === 'GLOBAL_UNEMPLOYED' ||
          employmentsMapSection.name === 'GLOBAL_INACTIVE' ||
          employmentsMapSection.name === 'GLOBAL_INACTIVE_UNEMPLOYED',
      }),
    GLOBAL_UNEMPLOYED: useUnemployedRegionAndAgeAndGenderByYearQuery({
      ...options,
      skip:
        activeSection === 'MENU_MATCHING_GAPS' ||
        activeSection === 'MENU_JOB_OPENINGS' ||
        employmentsMapSection.name === 'GLOBAL_EMPLOYED' ||
        employmentsMapSection.name === 'GLOBAL_INACTIVE',
    }),
    GLOBAL_INACTIVE: useInactiveReasonsAndRegionAndAgeAndGenderByYearQuery({
      ...options,
      skip:
        activeSection === 'MENU_MATCHING_GAPS' ||
        activeSection === 'MENU_JOB_OPENINGS' ||
        employmentsMapSection.name === 'GLOBAL_UNEMPLOYED' ||
        employmentsMapSection.name === 'GLOBAL_EMPLOYED',
    }),
    GLOBAL_INACTIVE_UNEMPLOYED:
      useInactiveAndUnemployedRegionAndAgeAndGenderByYearQuery({
        ...options,
        skip:
          activeSection === 'MENU_MATCHING_GAPS' ||
          activeSection === 'MENU_JOB_OPENINGS' ||
          employmentsMapSection.name === 'GLOBAL_EMPLOYED' ||
          employmentsMapSection.name === 'GLOBAL_UNEMPLOYED' ||
          employmentsMapSection.name === 'GLOBAL_INACTIVE',
      }),
  };

  let jobs = useOpenJobsBySectorAndRegionbyYearQuery({
    ...options,
    skip: activeSection === 'MENU_WORKFORCE',
  });

  let matchingGap = {
    GLOBAL_INACTIVE_UNEMPLOYED:
      useInactiveAndUnemployedRegionAndAgeAndGenderByYearQuery({
        ...options,
        skip:
          activeSection === 'MENU_WORKFORCE' ||
          activeSection === 'MENU_JOB_OPENINGS' ||
          employmentsMatchingTheGaps.name === 'GLOBAL_UNEMPLOYED' ||
          employmentsMatchingTheGaps.name === 'GLOBAL_INACTIVE',
      }),
    GLOBAL_UNEMPLOYED: useUnemployedRegionAndAgeAndGenderByYearQuery({
      ...options,
      skip:
        activeSection === 'MENU_WORKFORCE' ||
        activeSection === 'MENU_JOB_OPENINGS' ||
        employmentsMatchingTheGaps.name === 'GLOBAL_INACTIVE_UNEMPLOYED' ||
        employmentsMatchingTheGaps.name === 'GLOBAL_INACTIVE',
    }),
    GLOBAL_INACTIVE: useInactiveReasonsAndRegionAndAgeAndGenderByYearQuery({
      ...options,
      skip:
        activeSection === 'MENU_WORKFORCE' ||
        activeSection === 'MENU_JOB_OPENINGS' ||
        employmentsMatchingTheGaps.name === 'GLOBAL_INACTIVE_UNEMPLOYED' ||
        employmentsMatchingTheGaps.name === 'GLOBAL_UNEMPLOYED',
    }),
  };

  workforceData =
    employmentsMapSection.name === 'GLOBAL_INACTIVE_UNEMPLOYED'
      ? {
          GLOBAL_INACTIVE:
            !isDataLoading(workforceData['GLOBAL_INACTIVE']) &&
            findSelectedRegion(
              workforceData['GLOBAL_INACTIVE']?.data?.results?.values,
              activeRegion.id
            ),
          GLOBAL_UNEMPLOYED:
            !isDataLoading(workforceData['GLOBAL_UNEMPLOYED']) &&
            findSelectedRegion(
              workforceData['GLOBAL_UNEMPLOYED']?.data?.results?.values,
              activeRegion.id
            ),
          GLOBAL_INACTIVE_UNEMPLOYED:
            !isDataLoading(workforceData[employmentsMapSection.name]) &&
            findSelectedRegion(
              workforceData[employmentsMapSection.name]?.data?.results?.values,
              activeRegion.id
            ),
        }
      : !isDataLoading(workforceData[employmentsMapSection.name]) &&
        findSelectedRegion(
          workforceData[employmentsMapSection.name]?.data?.results?.values,
          activeRegion.id
        );

  jobs = !isDataLoading(jobs) && jobs?.data?.results;

  matchingGap =
    !isDataLoading(matchingGap[employmentsMatchingTheGaps.name]) &&
    matchingGap[employmentsMatchingTheGaps.name]?.data?.results;

  useEffect(() => {
    dispatch({
      type: 'TOGGLE_LOADING_NEW_MAP_DATA',
      payload: false,
    });
  }, [
    employmentsMapSection.name,
    employmentsMatchingTheGaps.name,
    dispatch,
    ages,
    gender,
  ]);

  return (
    <section id="map_section" tw="h-[100vh] relative">
      <NavbarMapSection />
      <div tw="flex h-[calc(100vh - 4.18rem)]">
        {activeSection === 'MENU_WORKFORCE' ? (
          <Workforce
            data={workforceData}
            tunisiaRegions={tunisiaRegions}
            insightsData={insightsData?.data?.insights}
            employmentsMapSection={employmentsMapSection.name}
          />
        ) : activeSection === 'MENU_JOB_OPENINGS' ? (
          <JobOpenings
            data={jobs}
            tunisiaRegions={tunisiaRegions}
            insightsData={insightsData?.data?.insights}
          />
        ) : (
          <MatchingGap
            matchingGap={matchingGap}
            jobs={jobs}
            tunisiaRegions={tunisiaRegions}
            employmentsMatchingTheGaps={employmentsMatchingTheGaps.name}
          />
        )}
      </div>
    </section>
  );
};

export default MapSection;

import logoFooter from '../../assets/images/footer/logoFooter.png';
import { useTranslation } from 'react-i18next';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const FooterSignature = () => { 
  const { t } = useTranslation();

  return (
  <>
    <h2 tw="text-[2rem] font-bold w-[9.37rem] leading-[2.37rem] mr-[5.56rem]">
      {t('HEADER_LOGO_MAIN')}
      <span tw="text-regular block mb-[0.75rem]">{t('FOOTER_BY')}</span>
      <span tw="text-[0.625rem] flex items-center">
        <img
          tw="mr-[0.93rem]"
          src={logoFooter}
          alt="Tunisia Jobs Clock Clock Footer"
        />
        world data lab
      </span>
    </h2>
  </>
)};

export default FooterSignature;

import { useContext, useEffect } from 'react';
import { TunisiaClockContext } from '../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import Sectors from './Sectors/Sectors';
import SubSectorsAgeAndGender from './SubSectorsAgeAndGender/SubSectorsAgeAndGender';
import FlipArrow from '../../../../../../../assets/images/hero_section/flipArrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const SectorsAgeGender = ({ data }) => {
  const [
    { employmentsMapSection, activeSector, activeSection, showBreakdownBar },
    dispatch,
  ] = useContext(TunisiaClockContext);

  useEffect(
    () =>
      dispatch({
        type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
        payload: {
          type: 'activeSubSector',
          name: data?.find(({ sector }) => sector?.id === activeSector)
            ?.subSectors[0]?.sector?.name,
        },
      }),
    [employmentsMapSection, activeSector, data, dispatch, activeSection]
  );

  return (
    <div tw="w-full mb-[1rem] rounded-[1.25rem] border border-gray-100 shadow p-[0.75rem 1rem] cursor-pointer">
      <div
        tw="flex justify-between"
        onClick={() =>
          dispatch({
            type: 'TOGGLE_FILTER_AND_BREAKDOWN_BAR',
            payload: {
              type: 'showBreakdownBar',
              showBreakdownBar: showBreakdownBar,
            },
          })
        }
      >
        <h3 tw="text-blue-400 text-xs font-medium">
          {t('FILTERS_AGE_GENDER_SECTOR')}
        </h3>
        <img
          css={[
            tw`transition duration-500`,
            showBreakdownBar && tw`rotate-180`,
          ]}
          alt="Flip arrow"
          src={FlipArrow}
        />
      </div>
      {showBreakdownBar && data && activeSector && (
        <div tw="flex pt-[1rem] text-[0.875rem]">
          <Sectors data={data} />
          <SubSectorsAgeAndGender data={data} />
        </div>
      )}
    </div>
  );
};

export default SectorsAgeGender;

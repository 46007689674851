import { links } from '../components/Header/NavbarList';
import { TRIGGER_DROPDOWN } from '../reducer/reducer';
/** @jsxImportSource @emotion/react */
import tw, { css } from 'twin.macro';

export const flipNumbersStyles = {
  fontSize: '4rem',
  fontWeight: 600,
};

export const isNotEmptyObject = (object) => Object.keys(object).length > 0;

export const removeActiveItemFromList = (type, state, dispatch, t) =>
  links[type]
    .filter((item) => item.id !== state[type].id)
    .map(({ id, name, description }) => (
      <li
        tw="p-[1rem] border-b border-b-gray-100"
        id={id}
        key={id}
        onClick={() => {
          if (
            type === 'employmentsMapSection' ||
            type === 'employmentsMatchingTheGaps'
          ) {
            dispatch({
              type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
              payload: {
                type: 'activeRegion',
                name: 'GLOBAL_TUNISIA',
                id: 'Tunisia',
              },
            });
            dispatch({
              type: 'TOGGLE_LOADING_NEW_MAP_DATA',
              payload: true,
            });
          }
          dispatch({
            type: 'SET_LATEST_TRIGGER',
            payload: TRIGGER_DROPDOWN,
          });
          dispatch({
            type: 'SET_ACTIVE_FILTER',
            payload: { type, name, id },
          });
        }}
      >
        <span css={[tw`text-sm text-gray-800`, description && tw`font-medium`]}>
          {t(name)}
        </span>
        <br />
        {description && (
          <span tw="text-xs text-gray-500">{t(description)}</span>
        )}
      </li>
    ));

export const sortTunisiaRegions = (data, activeRegion, dispatch) => {
  const copy = [...data].sort((a, b) => a.id.localeCompare(b.id));
  copy.unshift({ __typename: 'Region', id: 'Tunisia', name: 'Tunisia' });
  return copy
    .filter((item) => item.id !== activeRegion)
    .map(({ id, name }) => (
      <li
        tw="p-[1rem] border-b border-b-gray-100"
        id={id}
        key={id}
        onClick={() =>
          dispatch({
            type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
            payload: {
              type: 'activeRegion',
              id: id,
              name: name,
            },
          })
        }
      >
        <span tw="text-sm text-gray-800">{name}</span>
      </li>
    ));
};

export const jsonParse = (json) => JSON.parse(json);

export const checkDataLength = (data) => data.length > 5;

export const togglePopup = () =>
  css`
    &:hover {
      > div {
        ${tw`h-auto visible opacity-100`}
      }
    }
  `;

export const handlePopupMessage = (id, type) => {
  const result = links[type].find(({ name }) => name === id);
  return result.popup || result.description;
};

export const isDataLoading = (data) => data?.loading;

export const findRegionByRegionID = (data, region) =>
  data?.find((country) => country?.region?.name === region?.name);

export const findRegionOverall = (
  data,
  region,
  employmentsMapSection,
  activeSection
) => {
  if (
    employmentsMapSection === 'inactiveAndUnemployed' &&
    (activeSection === 'MENU_MATCHING_GAPS' ||
      activeSection === 'MENU_WORKFORCE')
  ) {
    const results = Object.values(
      [...data['Inactive']?.values, ...data['Unemployed']?.values].reduce(
        (acc, { region, overall }) => {
          acc[region.name] = {
            region,
            overall:
              (acc[region.name] ? acc[region.name].overall : 0) + overall.value,
          };
          return acc;
        },
        []
      )
    );
    return findRegionByRegionID(results, region)?.overall;
  } else if (activeSection === 'MENU_JOB_OPENINGS')
    return findRegionByRegionID(data['Job_Openings']?.values, region)?.overall
      ?.value;
  else
    return findRegionByRegionID(data[employmentsMapSection]?.values, region)
      ?.overall?.value;
};

export const tunisiaPopulation = (data) =>
  data?.find(({ region }) => region.id === 'Tunisia');

export const findSelectedRegion = (data, activeRegion) =>
  data?.find(({ region }) => region.id === activeRegion);

const calculateOverallValueAndDirection = (ages, genders, data) => {
  if (data)
    return ages.id === 'All' && genders === 'All genders'
      ? data?.overall
      : ages.id === 'All' && genders !== 'All genders'
      ? data[genders.toLowerCase()]
      : data?.values?.find((result) => {
          if (ages.name === `${result.ageGroup.from} - ${result.ageGroup.to}`)
            return result;
          return null;
        })[genders === 'All genders' ? 'overall' : genders.toLowerCase()];
};

export const overallValueAndDirection = (ages, genders, data) =>
  calculateOverallValueAndDirection(ages, genders, data);

export const percentageOfWorkingAgePopulation = (ages, genders, data) =>
  (
    (overallValueAndDirection(ages, genders, data)?.value /
      data?.numPopulation) *
    100
  ).toFixed(0);

import { createContext, useMemo, useReducer } from 'react';
import { initialState, reducer } from '../../reducer/reducer';
const TunisiaClockContext = createContext();

const TunisiaClockContextProvider = ({ children }) => {
  const initialFilters = useMemo(
    () => ({
      ...initialState,
    }),
    []
  );
  const [state, dispatch] = useReducer(reducer, initialFilters);

  return (
    <TunisiaClockContext.Provider value={[state, dispatch]}>
      {children}
    </TunisiaClockContext.Provider>
  );
};

export { TunisiaClockContext, TunisiaClockContextProvider };

import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { findSelectedRegion } from '../../../../../../../utils/common';
import DynamicSvg from '../../../../../../DynamicSvg/DynamicSvg';
import Loader from '../../../../../../Loader/Loader';
import { t } from 'i18next';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const SubSectorsOpenJobsRegion = ({ data }) => {
  const [{ activeSector, activeSubSector, activeRegion }] =
    useContext(TunisiaClockContext);

  const findInData = (data) =>
    findSelectedRegion(data?.values, activeRegion.id)?.superSectors?.find(
      ({ sector }) => sector?.name === activeSector
    );

  return (
    <article tw="pl-[0.75rem] w-full relative">
      {!activeSubSector ? (
        <Loader message={t("LOADER_SUBSECTORS_MESSAGE")} />
      ) : (
        <>
          <div tw="flex items-center justify-between text-gray-500 text-xs font-semiBold border-b border-b-gray-100 p-[0 0.5rem 0.5rem]">
            <span>{t('SECTORS_SUBSECTORS')}</span>
            <span>{t('GLOBAL_OPEN_JOBS')}</span>
          </div>
          <div
            className="scrollable"
            tw="h-[13rem] overflow-y-scroll text-gray-800 font-medium text-sm"
          >
            {findInData(data)?.subSectors.map(({ overall, sector }) => (
              <div
                key={sector?.name}
                tw="flex justify-between items-center p-[0.75rem 0.5rem] even-of-type:bg-tableGreen"
              >
                <span>{sector?.name}</span>
                <span tw="flex items-center gap-[0.5rem] justify-between pl-[1.438rem]">
                  <p tw="text-green-500 font-medium text-sm line-height[1.026rem]">
                    {overall?.value.toLocaleString()}
                  </p>
                  <DynamicSvg iconName={overall?.direction} size={10} />
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </article>
  );
};

export default SubSectorsOpenJobsRegion;

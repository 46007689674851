import { useEffect, useState } from 'react';

const useMousePosition = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouse = (e) => setPosition({ x: e.clientX, y: e.clientY });
    window.addEventListener('mousemove', handleMouse);

    return () => window.removeEventListener('mousemove', handleMouse);
  }, []);

  return position;
};

export default useMousePosition;

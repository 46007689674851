import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import { links } from '../../../../Header/NavbarList';
import axisArrow from '../../../../../assets/images/map_section/axis-arrow.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const MapLegend = () => {
  const [{ employmentsMapSection, employmentsMatchingTheGaps, activeSection }] =
    useContext(TunisiaClockContext);

  return activeSection === 'MENU_WORKFORCE' ||
    activeSection === 'MENU_JOB_OPENINGS' ? (
    <div tw="absolute bottom-[0.5rem] left-0 text-gray-800 text-xs bg-white rounded-[0.5rem] shadow w-[95px] p-[1rem] gap-[0.5rem] flex flex-col ml-[0.625rem] z-[400]">
      <h4>
        % of total{' '}
        {activeSection === 'MENU_JOB_OPENINGS'
          ? t('MAP_LEGEND_OPEN_JOBS')
          : `${t(`${employmentsMapSection.name}`).toLowerCase()} population`}
      </h4>
      {links.mapLegendValues.map(
        ({
          employedValue,
          GLOBAL_EMPLOYED,
          GLOBAL_INACTIVE,
          GLOBAL_UNEMPLOYED,
          Jobs,
          inactiveValue,
          unemployedValue,
          unemployedInactiveValue,
          jobsValue,
        }) => (
          <span key={employedValue} tw="flex items-center justify-between">
            <span
              tw="w-[1rem] h-[1rem] block rounded-[0.25rem]"
              style={{
                background:
                  activeSection === 'MENU_JOB_OPENINGS'
                    ? [Jobs]
                    : employmentsMapSection.name === 'GLOBAL_EMPLOYED'
                    ? [GLOBAL_EMPLOYED]
                    : employmentsMapSection.name === 'GLOBAL_INACTIVE'
                    ? [GLOBAL_INACTIVE]
                    : [GLOBAL_UNEMPLOYED],
              }}
            ></span>
            {activeSection === 'MENU_JOB_OPENINGS'
              ? jobsValue
              : employmentsMapSection.name === 'GLOBAL_EMPLOYED'
              ? employedValue
              : employmentsMapSection.name === 'GLOBAL_INACTIVE'
              ? inactiveValue
              : employmentsMapSection.name === 'GLOBAL_UNEMPLOYED'
              ? unemployedValue
              : unemployedInactiveValue}
          </span>
        )
      )}
    </div>
  ) : (
    <div tw="max-w-[12rem] absolute bottom-[0.5rem] gap-0 left-0 text-gray-800 text-xs bg-white rounded-[0.5rem] shadow p-[1rem 1rem 0.85rem 0.65rem] flex flex-col ml-[0.625rem] overflow-visible z-[400]">
      <div tw="flex">
        <div tw="-rotate-90 translate-y-[1.2rem] translate-x-[-3.35rem] text-right flex flex-col justify-center ml-[0.2rem]">
          <div tw="text-sm">{t('GLOBAL_OPEN_JOBS')}</div>
          <img
            alt="Arrow Y axis"
            width={158}
            src={axisArrow}
            tw="my-[0.5rem]"
          />
          <div tw="flex justify-end gap-[0.9rem] font-medium text-[0.625rem]">
            <span>0-5k</span>
            <span>5-10k</span>
            <span>10k+</span>
          </div>
        </div>
        <div tw="self-end grid grid-cols-3 w-[7.5rem] h-[7.5rem] rounded-[0.5rem] overflow-hidden ml-[-6.025rem]">
          <span tw="bg-[#014b9c]"></span>
          <span tw="bg-[#4a8ecd]"></span>
          <span tw="bg-[#3b8d73]"></span>
          <span tw="bg-[#6aa499]"></span>
          <span tw="bg-[#c7e99a]"></span>
          <span tw="bg-[#ced074]"></span>
          <span tw="bg-[#e8ae45]"></span>
          <span tw="bg-[#ef962e]"></span>
          <span tw="bg-[#ff6600]"></span>
        </div>
      </div>
      <div tw="flex flex-col text-right items-end mt-[0.7rem]">
        <div tw="flex gap-[0.9rem] font-medium text-[0.625rem] max-w-[9rem] ">
          <span>0-10k</span>
          <span>10-50k</span>
          <span>50k+</span>
        </div>
        <img
          alt="Arrow X axis"
          width={155}
          src={axisArrow}
          tw="m-[0.2rem 0 0.5rem] max-w-[8.9rem]"
        />
        <p tw="text-sm">{t(employmentsMatchingTheGaps.name)}</p>
      </div>
    </div>
  );
};

export default MapLegend;

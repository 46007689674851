import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import Loader from '../../../../../Loader/Loader';
import DynamicSvg from '../../../../../DynamicSvg/DynamicSvg';
import { findSelectedRegion } from '../../../../../../utils/common';
/** @jsxImportSource @emotion/react */
import { theme } from 'twin.macro';

const AgeAndGender = ({ matchingGap }) => {
  const [{ activeRegion }] = useContext(TunisiaClockContext);

  return (
    <article tw="w-full max-h-[15rem] h-full relative">
      {!findSelectedRegion(matchingGap?.values, activeRegion.id)?.values ? (
        <Loader message={t('LOADER_AGE_GROUPS')} />
      ) : (
        <>
          <div tw="flex items-center justify-between text-gray-500 font-semiBold border-b border-b-gray-100 p-[0 0.5rem 0.5rem]">
            <span>{t('GLOBAL_AGE')}</span>
            <DynamicSvg
              iconName="MaleGender"
              size={16}
              fill={theme`colors.gray.500`}
            />
            <DynamicSvg
              iconName="FemaleGender"
              size={16}
              fill={theme`colors.gray.500`}
            />
          </div>
          {findSelectedRegion(matchingGap?.values, activeRegion.id)?.values.map(
            ({ ageGroup, females, males }) => (
              <div
                key={ageGroup?.from}
                tw="flex justify-between items-center p-[0.55rem 0.5rem] even-of-type:bg-tableInactive"
              >
                <span tw="text-gray-800 text-sm w-[20%]">
                  {`${ageGroup?.from} - ${ageGroup?.to}`}
                </span>
                <span tw="font-medium text-sm flex gap-[0.5rem] items-center text-ochre-500 flex-1 justify-end">
                  {males?.value.toLocaleString()}
                  <DynamicSvg iconName={males?.direction} size={10} />
                </span>
                <span tw="font-medium text-sm flex gap-[0.5rem] items-center text-ochre-500 flex-1 justify-end">
                  {females?.value.toLocaleString()}
                  <DynamicSvg iconName={females?.direction} size={10} />
                </span>
              </div>
            )
          )}
          <div tw="flex justify-between items-center p-[0.20rem 0.5rem] even-of-type:bg-tableBlue">
            <span tw="text-gray-800 text-sm w-[20%]">Total</span>
            <span tw="font-medium text-sm flex gap-[0.5rem] items-center text-ochre-500 flex-1 justify-end">
              {findSelectedRegion(
                matchingGap?.values,
                activeRegion.id
              )?.males?.value.toLocaleString()}
              <DynamicSvg
                iconName={
                  findSelectedRegion(matchingGap?.values, activeRegion.id)
                    ?.males?.direction
                }
                size={10}
              />
            </span>
            <span tw="font-medium text-sm flex gap-[0.5rem] items-center text-ochre-500 flex-1 justify-end">
              {findSelectedRegion(
                matchingGap?.values,
                activeRegion.id
              )?.females?.value.toLocaleString()}
              <DynamicSvg
                iconName={
                  findSelectedRegion(matchingGap?.values, activeRegion.id)
                    ?.females?.direction
                }
                size={10}
              />
            </span>
          </div>
        </>
      )}
    </article>
  );
};

export default AgeAndGender;

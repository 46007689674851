import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper';
// import DynamicSvg from '../../../../../DynamicSvg/DynamicSvg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Insight = ({ employmentsMapSection, insightsData }) => (
  <div
    css={[
      tw`pt-[3rem] w-full text-left`,
      employmentsMapSection !== 'GLOBAL_UNEMPLOYED' && tw`p-[1rem 0 0rem] text-center`,
    ]}
  >
    <article>
      <Swiper
        fade="true"
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
        tw="h-[3.35rem]"
      >
        {insightsData.map((insight) => (
          <SwiperSlide key={insight + employmentsMapSection}>
            <div tw="text-xs font-semiBold text-gray-800">
              <span tw="text-green-500">{insight}</span>
              {/* <span tw="mx-[0.25rem]">{t('HEADER_REGION_HAS')}/span>
              <span tw="text-green-500">{}</span>
              <DynamicSvg iconName="GreenArrowUp" size={10} />
              <span tw="text-green-500">open jobs. </span>
              <span>Most of them are located in </span>
              <span tw="text-green-500">{insight}</span> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </article>
  </div>
);

export default Insight;

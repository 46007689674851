import { t } from 'i18next';
import { togglePopup } from '../../../../../utils/common';
import information from '../../../../../assets/images/map_section/information.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const InfoTooltip = () => (
  <div tw="flex items-center m-[1.2rem 0 0.6rem]">
    <span tw="text-sm">{t('JOB_OPPORTUNITIES')}</span>
    <div css={[tw`relative cursor-pointer`, togglePopup()]}>
      <div tw="z-[1] h-0 invisible opacity-0 transition duration-500 bg-gray-500 text-left absolute rounded-[0.5rem] p-[1rem] w-[17.25rem] left-[-8.5rem] bottom-[-8.5rem] text-white text-xs">
        <p tw="pb-[1rem]">{t('JOB_OPPORTUNITIES_INFO_ABOVE')}</p>
        <p>{t('JOB_OPPORTUNITIES_INFO_BELOW')}</p>
      </div>
      <img alt="Information icon" src={information} tw="pl-[0.5rem]" />
    </div>
  </div>
);

export default InfoTooltip;

import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import FlipNumbers from 'react-flip-numbers';
import RegionsAndSectorsEmployments from '../RegionsAndSectors/RegionsAndSectorsEmployments';
import RegionsAndSectorsJobs from '../RegionsAndSectors/RegionsAndSectorsJobs';
import DynamicSvg from '../../../../../DynamicSvg/DynamicSvg';
import Loader from '../../../../../Loader/Loader';
import useToggle from '../../../../../../custom-hooks/useToggle';
import Slider from '../../../../../SliderRange/Slider';
import InfoTooltip from '../../../JobOpenings/InfoTooltip/InfoTooltip';
import {
  removeActiveItemFromList,
  flipNumbersStyles,
  findSelectedRegion,
  sortTunisiaRegions,
  togglePopup,
} from '../../../../../../utils/common';
import Popup from '../../../../../Popup/Popup';
import arrow from '../../../../../../assets/images/common/arrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import CustomSlider from '../../../../../SliderRange/Slider';

const Inactive = ({ matchingGap, tunisiaRegions, jobs }) => {
  const [state, dispatch] = useContext(TunisiaClockContext);
  const [{ employmentsToggle, regionsToggle }, handleToggleState] = useToggle();


  return (
    <article tw="flex justify-between flex-col pr-[11.3125rem] max-w-[51rem] ml-auto w-full z-[1]">
      <div>
        <div tw="flex justify-between gap-[2rem]">
          <div tw="flex-1">
            <div tw="flex flex-col justify-between">
              <div tw="flex justify-between items-center">
                <ul tw="flex items-center max-w-[12rem] text-sm">
                  <li
                    tw="hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem] mr-[0.625rem] justify-between text-sm"
                    onClick={() =>
                      handleToggleState(regionsToggle, 'regionsToggle')
                    }
                  >
                    {state.activeRegion.id === 'Tunisia'
                      ? t('GLOBAL_TUNISIA')
                      : state.activeRegion.name}
                    <img
                      css={[
                        tw`ml-[1rem] transition duration-500`,
                        regionsToggle && tw`rotate-180`,
                      ]}
                      alt="Arrow"
                      width={14}
                      src={arrow}
                    />
                    {regionsToggle && (
                      <ul
                        tw="text-gray-800 text-xs absolute top-[2.9rem] left-0 w-[8rem] cursor-pointer shadow bg-white max-h-[21rem] overflow-y-scroll text-left z-[2]"
                        className="scrollable scrollableRegions"
                      >
                        {sortTunisiaRegions(
                          tunisiaRegions,
                          state.activeRegion.id,
                          dispatch
                        )}
                      </ul>
                    )}
                  </li>
                  <li>{t('HEADER_REGION_HAS')}</li>
                </ul>
              </div>
              <div tw="flex flex-col p-[1rem 0]">
                <div tw="flex items-center justify-between">
                  <span tw="font-semiBold mr-[0.625rem] h-[3rem] flex relative min-w-[5rem] text-orange-500">
                    {!matchingGap?.overall?.value ? (
                      <Loader message="" />
                    ) : (
                      <FlipNumbers
                        duration={1}
                        play
                        numbers={findSelectedRegion(
                          matchingGap?.values,
                          state.activeRegion.id
                        ).overall?.value.toLocaleString()}
                        width={35}
                        height={80}
                        numberStyle={flipNumbersStyles}
                        nonNumberStyle={flipNumbersStyles}
                        perspective={100000}
                      />
                    )}
                  </span>
                  <DynamicSvg
                    iconName={matchingGap?.overall?.direction}
                    size={24}
                  />
                </div>
              </div>
              <ul tw="flex items-center m-[0 0.625rem 1rem] text-sm">
                <li
                  css={[tw`hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem] justify-between`, 
                    togglePopup()
                  ]}
                  onClick={() =>
                    handleToggleState(employmentsToggle, 'employmentsToggle')
                  }
                >
                  {t(state.employmentsMatchingTheGaps.name).toLowerCase()}
                  <img
                    css={[
                      tw`ml-[1rem] transition duration-500`,
                      employmentsToggle && tw`rotate-180`,
                    ]}
                    alt="Arrow"
                    width={14}
                    src={arrow}
                  />
                  {!employmentsToggle && (
                    <Popup
                      message={t('GLOBAL_INACTIVE_DESC_LONG')}
                      isFromEmploymentsMap={true}
                    />
                  )}
                  {employmentsToggle && (
                    <ul tw="text-gray-800 text-xs absolute top-[2.9rem] left-0 cursor-pointer shadow bg-white w-[17rem] z-[2]">
                      {removeActiveItemFromList(
                        'employmentsMatchingTheGaps',
                        state,
                        dispatch,
                        t
                      )}
                    </ul>
                  )}
                </li>
                <li tw="ml-[0.625rem]">{t('HEADER_TUNISIA_PERSONS')}</li>
              </ul>
            </div>
            <div tw="flex gap-[1.5rem] items-start">
              {matchingGap && (
                <RegionsAndSectorsEmployments matchingGap={matchingGap} />
              )}
            </div>
          </div>
          <div tw="flex-1">
            <div tw="flex flex-col justify-between">
              <div tw="flex flex-col p-[0.5rem 0 1rem]">
                <p tw="h-[2.81rem] text-sm">{t('FILTER_MAP_BAR_AND')}</p>
                <div tw="flex items-center justify-between">
                  <span tw="font-semiBold mr-[0.625rem] mb-[0.5rem] h-[3rem] flex relative min-w-[5rem] text-green-400">
                    {!jobs?.overall?.value ? (
                      <Loader message="" />
                    ) : (
                      <FlipNumbers
                        duration={1}
                        play
                        numbers={findSelectedRegion(
                          jobs?.values,
                          state.activeRegion.id
                        ).overall?.value.toLocaleString()}
                        width={35}
                        height={80}
                        numberStyle={flipNumbersStyles}
                        nonNumberStyle={flipNumbersStyles}
                        perspective={100000}
                      />
                    )}
                  </span>
                  <DynamicSvg iconName={jobs?.overall?.direction} size={24} />
                </div>
                <InfoTooltip />
              </div>
            </div>
            <div>
              {jobs && <RegionsAndSectorsJobs matchingGapJobs={jobs} />}
            </div>
          </div>
        </div>
      </div>
      <CustomSlider isFromMapSection={true} />
    </article>
  );
};

export default Inactive;

import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import DynamicSvg from '../../../../../../../DynamicSvg/DynamicSvg';
import { checkDataLength } from '../../../../../../../../utils/common';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import { t } from 'i18next';

const Groups = ({ data }) => {
  const [{ activeReason }, dispatch] = useContext(TunisiaClockContext);

  return (
    <article
      className="scrollable scrollableReasons"
      css={[
        tw`border-r-[0.063rem] border-gray-100 pr-[0.75rem]`,
        checkDataLength(data) && tw`max-h-[13.3rem] overflow-y-scroll w-full`,
      ]}
    >
      <h4 tw="text-gray-500 pb-[1.5rem] font-semiBold text-xs">
        {t('GLOBAL_GROUPS')}
      </h4>
      {data?.map((item) => (
        <button
          key={item?.reason.name}
          css={[
            tw`flex justify-between w-[16.875rem] border border-blue-400 rounded-[3.37rem] p-[0.5rem 1rem] hover:bg-blue-200 text-gray-800 mb-[0.5rem] items-center`,
            activeReason === item?.reason.name &&
              tw`bg-blue-400 text-white hover:bg-blue-400`,
          ]}
          onClick={() =>
            dispatch({
              type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
              payload: {
                type: 'activeReason',
                id: item?.reason.name,
              },
            })
          }
        >
          <span tw="flex justify-between items-center gap-[0.5rem]">
            <DynamicSvg
              iconName={item?.reason.id}
              size={16}
              fill={activeReason === item?.reason.name ? 'white' : 'black'}
            />
            <p tw="text-sm">{item?.reason.name}</p>
          </span>
          <span
            css={[
              tw`flex justify-between items-center gap-[0.5rem] text-sm font-medium text-ochre-500`,
              activeReason === item?.reason.name && tw`text-white`,
            ]}
          >
            <p>{item?.overall?.value.toLocaleString()}</p>
            <DynamicSvg iconName={item?.overall?.direction} size={10} />
          </span>
        </button>
      ))}
    </article>
  );
};

export default Groups;

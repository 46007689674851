import Map from '../Map/Map';
import Breakdowns from './Breakdowns/Breakdowns';
import MapLegend from '../Map/MapLegend/MapLegend';
import sea from '../../../../assets/images/map_section/sea.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Workforce = ({
  data,
  tunisiaRegions,
  employmentsMapSection,
  insightsData,
}) => (
  <>
    <article
      css={[
        tw`h-full w-[30.875rem] relative`,
        data &&
          (employmentsMapSection === 'GLOBAL_INACTIVE'
            ? tw`box-shadow[5rem 0 3rem #FFFDFA]`
            : employmentsMapSection === 'GLOBAL_UNEMPLOYED'
            ? tw`box-shadow[5rem 0 3rem #FFFAF4]`
            : tw`box-shadow[5rem 0 3rem #f2faff]`),
      ]}
      style={{
        backgroundImage: `url(${data && sea})`,
        backgroundColor:
          employmentsMapSection === 'GLOBAL_INACTIVE'
            ? '#FFFDFA'
            : employmentsMapSection === 'GLOBAL_UNEMPLOYED'
            ? '#FFFAF4'
            : '#f2faff',
      }}
    >
      <Map />
      <MapLegend />
    </article>
    <Breakdowns
      data={data}
      tunisiaRegions={tunisiaRegions}
      employmentsMapSection={employmentsMapSection}
      insightsData={insightsData}
    />
  </>
);

export default Workforce;

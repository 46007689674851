import { TunisiaClockContextProvider } from './views/TunisiaClock/TunisiaClockContextProvider';
import TunisiaClock from './views/TunisiaClock/TunisiaClock';
import './App.css';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import en from './i18n/en.json';
import fr from './i18n/fr.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(detector) // detect user language
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: en,
      },
      fr: {
        translation: fr,
      },
    },
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: true, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

i18n.on('initialised', (a) => localStorage.setItem('lang', a));

const App = () => (
  <TunisiaClockContextProvider>
    <TunisiaClock />
  </TunisiaClockContextProvider>
);

export default App;

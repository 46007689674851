import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import DynamicSvg from '../../../../../../DynamicSvg/DynamicSvg';
import Loader from '../../../../../../Loader/Loader';
import { findSelectedRegion } from '../../../../../../../utils/common';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const SubSectorsOpenJobsRegion = ({ matchingGapJobs }) => {
  const [{ activeRegion }] = useContext(TunisiaClockContext);

  return (
    <article tw="w-full relative">
      {!findSelectedRegion(matchingGapJobs?.values, activeRegion.id)
        ?.superSectors ? (
        <Loader message={t("LOADER_SECTORS_MESSAGE")} />
      ) : (
        <>
          <div tw="flex items-center justify-between text-gray-500 text-xs font-semiBold border-b border-b-gray-100 p-[0 0.5rem 0.5rem]">
            <span>{t('SECTORS_SUBSECTORS')}</span>
            <span>{t('GLOBAL_OPEN_JOBS')}</span>
          </div>
          <div
            className={
              findSelectedRegion(matchingGapJobs?.values, activeRegion.id)
                ?.superSectors.length > 3 && 'scrollable'
            }
            css={[
              tw`max-h-[13rem] h-full overflow-y-scroll text-gray-800 font-medium text-sm`,
              findSelectedRegion(matchingGapJobs?.values, activeRegion.id)
                ?.superSectors.length <= 3 && tw`overflow-y-hidden`,
            ]}
          >
            {findSelectedRegion(
              matchingGapJobs?.values,
              activeRegion.id
            )?.superSectors?.map(({ overall, sector }) => (
              <div
                key={sector?.name}
                tw="flex justify-between items-center p-[0.75rem 0.5rem] even-of-type:bg-tableGreen"
              >
                <span>{sector?.name}</span>
                <span tw="flex items-center gap-[0.5rem] justify-between pl-[1.438rem]">
                  <p tw="text-green-500 font-medium text-sm line-height[1.026rem]">
                    {overall?.value.toLocaleString()}
                  </p>
                  <DynamicSvg iconName={overall?.direction} size={10} />
                </span>
              </div>
            ))}
          </div>
        </>
      )}
    </article>
  );
};

export default SubSectorsOpenJobsRegion;

import { t } from 'i18next';
import giz from '../../assets/images/footer/logo_new.png';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const FooterGiz = () => (
  <div tw="pl-[1.667rem] border-l border-l-white lg:border-0 lg:p-0">
    <h2 tw="pb-[1.5rem]">{t('FOOTER_SIGNATURE')}</h2>
    <div tw="bg-white rounded-[0.5rem] w-auto h-auto max-w-[48rem] flex justify-center">
      <img src={giz} alt="GIZ" />
    </div>
  </div>
);

export default FooterGiz;

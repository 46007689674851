import tw, { css, styled, theme } from 'twin.macro';
import { checkStyleAttr } from '../../utils/gridSystem';
import { string, number, oneOfType, arrayOf } from 'prop-types';

export const Grid = styled.div(
  ({ padding, margin, cols, gap, justifyContent, alignItems, md }) => [
    tw`mx-auto grid`,
    css`
      max-width: ${theme`screens.2xl.max`};
      padding: ${checkStyleAttr(padding)};
      margin: ${checkStyleAttr(margin)};
      grid-template-columns: repeat(${cols}, minmax(0, 1fr));
      gap: ${checkStyleAttr(gap)};
      justify-content: ${checkStyleAttr(justifyContent)};
      align-items: ${checkStyleAttr(alignItems)};

      @media (max-width: ${theme`screens.xl.max`}) {
        padding: 1rem 2rem;
      }

      @media (max-width: ${theme`screens.lg.max`}) {
        padding: 1rem;
      }

      @media (max-width: ${theme`screens.md.max`}) {
        grid-template-columns: repeat(${md}, minmax(0, 1fr));
      }
    `,
  ]
);

export const Col = styled.div(({ padding, margin, col, md, lg }) => [
  css`
    padding: ${checkStyleAttr(padding)};
    margin: ${checkStyleAttr(margin)};
    grid-column: span ${col};

    @media (max-width: ${theme`screens.lg.max`}) {
      grid-column: span ${lg};
    }

    @media (max-width: ${theme`screens.md.max`}) {
      grid-column: span ${md};
    }
  `,
]);

export const Container = styled.div([tw``, css``]);

Grid.propTypes = {
  padding: oneOfType([number, arrayOf(number)]),
  margin: oneOfType([number, arrayOf(number)]),
  cols: number.isRequired,
  gap: number,
  justifyContent: string,
  alignItems: string,
  md: number,
};

Grid.defaultProps = {
  padding: [16, 181],
  margin: null,
  cols: 12,
  gap: 24,
  justifyContent: null,
  alignItems: null,
  md: 4,
};

Col.propTypes = {
  padding: oneOfType([number, arrayOf(number)]),
  margin: oneOfType([number, arrayOf(number)]),
  col: number.isRequired,
  md: number,
  lg: number,
};

Col.defaultProps = {
  padding: null,
  margin: null,
  col: 3,
  md: null,
  lg: null,
};

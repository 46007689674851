import MailchimpSubscribe from 'react-mailchimp-subscribe';
import Form from './Form.js';

const url =
  'https://worlddata.us13.list-manage.com/subscribe/post-json?u=3840f1496862a7a719676d23a&id=72179f20ac';

const FormContainer = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status }) => (
      <Form onValidated={(data) => subscribe(data)} status={status} />
    )}
  />
);

export default FormContainer;

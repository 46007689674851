const convertPxToRem = (styleAttr) => `${styleAttr / 16}rem`;

export const checkStyleAttr = (styleAttr = '') => {
  if (Array.isArray(styleAttr)) {
    let updatedArray = [];
    styleAttr.map((unit) =>
      unit === 0 ? 0 : updatedArray.push(convertPxToRem(unit))
    );
    return updatedArray.join(' ');
  } else if (typeof styleAttr === 'number') {
    return `${convertPxToRem(styleAttr)}`;
  } else if (typeof styleAttr === 'string') {
    return styleAttr;
  }
};

import { useContext } from 'react';
import { TunisiaClockContext } from '../../views/TunisiaClock/TunisiaClockContextProvider';
import { useTranslation } from 'react-i18next';
import { TRIGGER_NAVBAR } from '../../reducer/reducer';
import useToggle from '../../custom-hooks/useToggle';
import {
  removeActiveItemFromList,
  handlePopupMessage,
  togglePopup,
} from '../../utils/common';
import Popup from '../Popup/Popup';
import arrow from '../../assets/images/common/arrow.svg';
import reset from '../../assets/images/header/reset.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Navbar = () => {
  const [state, dispatch] = useContext(TunisiaClockContext);
  const [
    { employmentsToggle, gendersToggle, agesToggle, languageToggle },
    handleToggleState,
  ] = useToggle();
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    localStorage.setItem('i18nextLng', lng);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('lang', lng);
    window.location.search = urlParams;
  };

  return (
    <ul
      css={[
        tw`flex justify-between items-center text-xs text-gray-800 md:absolute top-[2.7rem] left-0 w-full md:inset-0 md:flex-col md:justify-evenly md:text-center md:mt-[4.18rem] md:translate-x-[-100%] transition md:duration-500 md:h-[calc(100vh - 4.18rem)]`,
      ]}
    >
      <li>{t('GLOBAL_VIEW')}</li>
      <li
        css={[
          tw`hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem 0.625rem 1rem]`,
          togglePopup(),
        ]}
        onClick={() => {
          dispatch({
            type: 'SET_LATEST_TRIGGER',
            payload: TRIGGER_NAVBAR,
          });
          handleToggleState(languageToggle, 'languageToggle');
        }}
      >
        {i18n.language === 'en' ? 'English' : 'Français'}
        <img
          css={[
            tw`ml-[1rem] transition duration-500`,
            languageToggle && tw`rotate-180`,
          ]}
          alt="Arrow"
          width={14}
          src={arrow}
        />
        {languageToggle && (
          <ul tw="text-gray-800 text-xs absolute top-[2.7rem] left-0 w-full cursor-pointer shadow bg-white">
            <li
              onClick={() => changeLanguage('en')}
              css={[
                tw`p-[1rem] border-b border-b-gray-100`,
                i18n.language === 'en' && tw`hidden`,
              ]}
            >
              <span tw="text-sm text-gray-800 font-medium">English</span>
            </li>
            <li
              css={[
                tw`p-[1rem] border-b border-b-gray-100`,
                i18n.language === 'fr' && tw`hidden`,
              ]}
              onClick={() => changeLanguage('fr')}
            >
              <span tw="text-sm text-gray-800 font-medium">Français</span>
            </li>
          </ul>
        )}
      </li>
      <li
        css={[
          tw`hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem 0.625rem 1rem]`,
          togglePopup(),
        ]}
        onClick={() => {
          dispatch({
            type: 'SET_LATEST_TRIGGER',
            payload: TRIGGER_NAVBAR,
          });
          handleToggleState(employmentsToggle, 'employmentsToggle');
        }}
      >
        {t(state.employmentsHeroSection.name)}
        <img
          css={[
            tw`ml-[1rem] transition duration-500`,
            employmentsToggle && tw`rotate-180`,
          ]}
          alt="Arrow"
          width={14}
          src={arrow}
        />
        {!employmentsToggle && (
          <Popup
            message={t(
              handlePopupMessage(
                state.employmentsHeroSection.name,
                'employmentsHeroSection'
              )
            )}
            isFromEmployments={true}
          />
        )}
        {employmentsToggle && (
          <ul tw="text-gray-800 text-xs absolute top-[2.7rem] left-0 w-[17.75rem] cursor-pointer shadow bg-white">
            {removeActiveItemFromList(
              'employmentsHeroSection',
              state,
              dispatch,
              t
            )}
          </ul>
        )}
      </li>
      <li
        css={[tw`hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem 0.625rem 1rem] max-w-[8rem] w-full justify-between`, 
        i18n.language === 'fr' && tw`max-w-[9rem]`
        ]}
        onClick={() => handleToggleState(gendersToggle, 'gendersToggle')}
      >
        {t(state.genders.name)}
        <img
          css={[
            tw`ml-[1rem] transition duration-500`,
            gendersToggle && tw`rotate-180`,
          ]}
          alt="Arrow"
          width={14}
          src={arrow}
        />
        {gendersToggle && (
          <ul tw="text-gray-800 text-xs absolute top-[2.7rem] left-0 w-full cursor-pointer shadow bg-white">
            {removeActiveItemFromList('genders', state, dispatch, t)}
          </ul>
        )}
      </li>
      <li>{t('GLOBAL_FROM')}</li>
      <li
        tw="hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex p-[0.625rem 0.625rem 1rem]"
        onClick={() => handleToggleState(agesToggle, 'agesToggle')}
      >
        {t(state.ages.name)}
        <img
          css={[
            tw`ml-[1rem] transition duration-500`,
            agesToggle && tw`rotate-180`,
          ]}
          alt="Arrow"
          width={14}
          src={arrow}
        />
        {agesToggle && (
          <ul tw="text-gray-800 text-xs absolute top-[2.7rem] left-0 min-w-[5.31rem] w-full cursor-pointer shadow bg-white">
            {removeActiveItemFromList('ages', state, dispatch, t)}
          </ul>
        )}
      </li>
      <li>{t('HEADER_IN_TUNISIA')}</li>
      <li
        css={[
          tw`text-gray-200 flex justify-center items-center p-[0.56rem 1rem] gap-[0.25rem] h-[2.215rem] w-[8.43rem] border border-gray-100 rounded-[3.37rem] pointer-events-none`,
          state.reset &&
            tw`text-gray-800 cursor-pointer pointer-events-auto border-blue-400`,
            i18n.language === 'fr' && tw`w-[11.43rem]`
        ]}
        onClick={() =>
          dispatch({
            type: 'RESET_FILTERS',
          })
        }
      >
        <img alt="Reset" src={reset} />
        <span>{t('GLOBAL_FILTERS_RESET')}</span>
      </li>
    </ul>
  );
};

export default Navbar;

import Inactive from './Inactive/Inactive';
import Unemployed from './Unemployed/Unemployed';
import InactiveAndUnemployed from './InactiveAndUnemployed/InactiveAndUnemployed';

const Breakdowns = ({
  matchingGap,
  jobs,
  tunisiaRegions,
  employmentsMatchingTheGaps,
}) =>
  employmentsMatchingTheGaps === 'GLOBAL_INACTIVE' ? (
    <Inactive
      matchingGap={matchingGap}
      jobs={jobs}
      tunisiaRegions={tunisiaRegions}
    />
  ) : employmentsMatchingTheGaps === 'GLOBAL_UNEMPLOYED' ? (
    <Unemployed
      matchingGap={matchingGap}
      jobs={jobs}
      tunisiaRegions={tunisiaRegions}
    />
  ) : (
    <InactiveAndUnemployed
      matchingGap={matchingGap}
      jobs={jobs}
      tunisiaRegions={tunisiaRegions}
    />
  );

export default Breakdowns;

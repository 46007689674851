import { useContext, Fragment } from 'react';
import { TunisiaClockContext } from '../../../views/TunisiaClock/TunisiaClockContextProvider';
import {
  useUnemployedAgeAndGenderByYearQuery,
  useEmployedAgeAndGenderByYearQuery,
  useInactiveAgeAndGenderByYearQuery,
} from '../../../generated/graphql.d.ts';
import { t } from 'i18next';
import { Grid, Col } from '../../../assets/styles/grid';
import Logo from '../../Header/Logo';
import Navbar from '../../Header/Navbar';
import FlipNumbers from 'react-flip-numbers';
import {
  togglePopup,
  handlePopupMessage,
  overallValueAndDirection,
  percentageOfWorkingAgePopulation,
} from '../../../utils/common';
import Slider from '../../SliderRange/Slider';
import DynamicSvg from '../../DynamicSvg/DynamicSvg';
import Loader from '../../Loader/Loader';
import Popup from '../../Popup/Popup';
import useToggle from '../../../custom-hooks/useToggle';
import useActivePage from '../../../custom-hooks/useActivePage';
import InformationIcon from '../../../assets/images/hero_section/informationIcon.svg';
import dataRequestIcon from '../../../assets/images/form/dataRequestIcon.svg';
import FlipArrow from '../../../assets/images/hero_section/flipArrow.svg';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';
import CustomSlider from '../../SliderRange/Slider';

const TunisiaHero = () => {
  const [
    { employmentsHeroSection, ages, genders, selectedYear, showModalForm },
    dispatch,
  ] = useContext(TunisiaClockContext);
  const [{ breakdownToggle }, handleToggleState] = useToggle();
  const activePage = useActivePage();

  const options = {
    variables: {
      year: selectedYear,
    },
  };

  const styles = {
    fontSize: breakdownToggle ? '4vw' : '6vw',
    fontWeight: 600,
  };

  const data = {
    Employed: useEmployedAgeAndGenderByYearQuery({
      ...options,
      skip:
        activePage === 'map_section' ||
        employmentsHeroSection.name === 'GLOBAL_UNEMPLOYED' ||
        employmentsHeroSection.name === 'GLOBAL_INACTIVE' ||
        employmentsHeroSection.name === 'GLOBAL_INACTIVE_UNEMPLOYED',
    }),
    Unemployed: useUnemployedAgeAndGenderByYearQuery({
      ...options,
      skip:
        activePage === 'map_section' ||
        employmentsHeroSection.name === 'GLOBAL_EMPLOYED' ||
        employmentsHeroSection.name === 'GLOBAL_INACTIVE',
    }),
    Inactive: useInactiveAgeAndGenderByYearQuery({
      ...options,
      skip:
        activePage === 'map_section' ||
        employmentsHeroSection.name === 'GLOBAL_UNEMPLOYED' ||
        employmentsHeroSection.name === 'GLOBAL_EMPLOYED',
    }),
  };

  const ageGroupValues = data[
    employmentsHeroSection.id
  ]?.data?.results?.values?.flatMap(({ females, males }) => [
    females.value,
    males.value,
  ]);

  const maxAgeGroupValue = ageGroupValues && Math.max(...ageGroupValues);
  const finalValues = data[
    employmentsHeroSection.id
  ]?.data?.results?.values?.map((item) => {
    return {
      ...item,
      heigthMale: (100 / maxAgeGroupValue) * item.males.value,
      heigthFemale: (100 / maxAgeGroupValue) * item.females.value,
    };
  });

  return (
    <section
      id="hero_section"
      css={[
        tw`h-[100vh] transition duration-1000 relative`,
        employmentsHeroSection.name === 'GLOBAL_EMPLOYED'
          ? tw`bg-employed`
          : employmentsHeroSection.name === 'GLOBAL_INACTIVE'
            ? tw`bg-inactive`
            : tw`bg-unemployed`,
      ]}
    >
      <>
        <Grid
          as="header"
          cols={12}
          alignItems="center"
          tw="absolute w-full z-[1] left-[50%] translate-x-[-50%]"
        >
          <Col col={2} md={3} tw="flex">
            <Logo />
          </Col>
          <Col as="nav" col={10} md={1} tw="md:justify-self-end">
            <Navbar />
          </Col>
        </Grid>
        <article
          css={[
            tw`pt-28 flex items-center justify-center h-[100vh]`,
            breakdownToggle && tw`pt-[4rem] h-[76vh]`,
          ]}
        >
          <button
            tw="text-blue-100 absolute right-0 top-[11%] bg-blue-400 flex p-[1.188rem 1.625rem] rounded-bl-[0.5rem] rounded-tl-[0.5rem] w-[9rem] h-[4rem] items-center hover:bg-blue-500 text-sm text-left gap-[0.625rem]"
            onClick={() => {
              dispatch({
                type: 'SHOW_MODAL_FORM',
                payload: showModalForm,
              });
            }}
          >
            <img alt="Data request" src={dataRequestIcon} />
            {t('GLOBAL_REQUEST_DATA')}
          </button>
          <div tw="mr-[auto]">
            <DynamicSvg
              size={951}
              iconName={
                ages.id === 'All'
                  ? 'FifteenToTwentyfour' +
                  employmentsHeroSection.id +
                  genders.id.replace(' ', '')
                  : ages.id +
                  employmentsHeroSection.id +
                  genders.id.replace(' ', '')
              }
              cssStyle={breakdownToggle}
            />
          </div>
          <div tw="absolute right-[11.3125rem] text-right">
            <h2
              css={[
                tw`text-[2rem] text-right mt-[-4.75rem] font-regular text-gray-800 transition duration-500`,
                breakdownToggle && tw`text-[1.5rem]`,
              ]}
            >
              <strong>{t('GLOBAL_TUNISIA')} </strong>
              {selectedYear > new Date().getFullYear()
                ? t('HEADER_REGION_WILL_HAVE')
                : t('HEADER_REGION_HAS')}
              {!overallValueAndDirection(
                ages,
                genders.id,
                data[employmentsHeroSection.id]?.data?.results
              )?.value ? (
                <div tw="relative p-[2.5rem 0]">
                  <Loader message="" />
                </div>
              ) : (
                <span
                  css={[
                    tw`flex items-center justify-end`,
                    employmentsHeroSection.name === 'GLOBAL_EMPLOYED'
                      ? tw`text-blue-400`
                      : employmentsHeroSection.name === 'GLOBAL_INACTIVE'
                        ? tw`text-ochre-500`
                        : tw`text-orange-500`,
                    breakdownToggle && tw`my-[1rem] mx-0`,
                  ]}
                >
                  <FlipNumbers
                    duration={1}
                    play
                    numbers={overallValueAndDirection(
                      ages,
                      genders.id,
                      data[employmentsHeroSection.id]?.data?.results
                    )?.value?.toLocaleString()}
                    perspective={100000}
                    width={
                      window.screen.width >= 1800
                        ? 85
                        : breakdownToggle
                          ? 55
                          : 65
                    }
                    height={breakdownToggle ? 80 : 115}
                    numberStyle={styles}
                    nonNumberStyle={styles}
                  />
                  <DynamicSvg
                    iconName={
                      overallValueAndDirection(
                        ages,
                        genders.id,
                        data[employmentsHeroSection.id]?.data?.results
                      )?.direction
                    }
                    cssStyle="bigDirection"
                    size={24}
                  />
                </span>
              )}
              <div
                css={[
                  tw`relative flex w-full items-center justify-end cursor-pointer`,
                  togglePopup(),
                ]}
              >
                {t(employmentsHeroSection.name).toLowerCase()}
                <img
                  tw="px-[0.625rem]"
                  alt="Information"
                  src={InformationIcon}
                />
                {t(genders.name).toLowerCase()}
                <Popup
                  message={t(
                    handlePopupMessage(
                      employmentsHeroSection.name,
                      'employmentsHeroSection'
                    )
                  )}
                />
              </div>
              {ages.id !== 'All' && (
                <div>{`${t('AGE_BETWEEN').toLowerCase()} ${ages.name
                  } y/o`}</div>
              )}
              <div tw="text-regular pt-[0.75rem] flex justify-end">
                {isNaN(
                  percentageOfWorkingAgePopulation(
                    ages,
                    genders.id,
                    data[employmentsHeroSection.id]?.data?.results
                  )
                ) ? (
                  <span tw="relative w-[3rem]">
                    <Loader message="" />
                  </span>
                ) : (
                  <span
                    css={[
                      tw`pr-[0.25rem]`,
                      employmentsHeroSection.name === 'GLOBAL_EMPLOYED'
                        ? tw`text-blue-400`
                        : employmentsHeroSection.name === 'GLOBAL_INACTIVE'
                          ? tw`text-ochre-500`
                          : tw`text-orange-500`,
                    ]}
                  >
                    {percentageOfWorkingAgePopulation(
                      ages,
                      genders.id,
                      data[employmentsHeroSection.id]?.data?.results
                    )}
                    %
                  </span>
                )}
                <span> {t('HEADER_TUNISIA_PERCENT_OF')}</span>
              </div>
            </h2>
            <div
              tw="flex items-center justify-end pt-[1.6rem]"
              onClick={() =>
                handleToggleState(breakdownToggle, 'breakdownToggle')
              }
            >
              <h3 tw="cursor-pointer mr-[0.5rem]">
                {t('GLOBAL_FILTERS_BREAKDOWN')}
              </h3>
              <img
                css={[
                  tw`transition duration-500`,
                  breakdownToggle && tw`rotate-180`,
                ]}
                alt="Arrow"
                src={FlipArrow}
              />
            </div>
          </div>
        </article>
        <article
          tw="flex items-center justify-between m-0 bottom-[7rem] px-[11.3125rem] transition duration-500 relative translate-y-[100%] translate-x-[100%] h-0 left-[100%]"
          css={[
            breakdownToggle &&
            tw`translate-y-[0] h-auto left-[50%] translate-x-[-50%]`,
          ]}
          style={{ maxWidth: theme`screens.2xl.max` }}
        >
          {finalValues?.map(
            ({ ageGroup, females, males, heigthMale, heigthFemale }) => (
              <Fragment key={ageGroup.from}>
                <div>
                  <p tw="pb-[2rem]">
                    {t('AGE_BETWEEN')} {ageGroup.from} - {ageGroup.to}
                  </p>
                  <div tw="flex justify-between items-end gap-[1.5rem]">
                    <div
                      css={[
                        (genders.id === 'Males' ||
                          genders.id === 'All genders') &&
                          (`${ageGroup.from} - ${ageGroup.to}` === ages.name ||
                            ages.id === 'All')
                          ? tw`opacity-100`
                          : tw`opacity-25`,
                      ]}
                    >
                      <div tw="h-[3.5rem] flex flex-col justify-end">
                        <p tw="mt-[-2.5rem] mb-[0.25rem]">
                          {males.value.toLocaleString()}
                        </p>
                        <div
                          style={{ height: `${heigthMale}%` }}
                          css={[
                            tw`rounded-tr-[0.25rem] rounded-tl-[0.25rem]`,
                            employmentsHeroSection.name === 'GLOBAL_EMPLOYED'
                              ? tw`bg-blue-400`
                              : employmentsHeroSection.name ===
                                'GLOBAL_INACTIVE'
                                ? tw`bg-ochre-400`
                                : tw`bg-orange-400`,
                          ]}
                        ></div>
                      </div>
                      <DynamicSvg
                        cssStyle="gender"
                        size={16}
                        iconName="MaleGender"
                        fill={
                          employmentsHeroSection.name === 'GLOBAL_EMPLOYED'
                            ? theme`colors.blue.350`
                            : employmentsHeroSection.name === 'GLOBAL_INACTIVE'
                              ? theme`colors.ochre.500`
                              : theme`colors.orange.500`
                        }
                      />
                    </div>
                    <div
                      css={[
                        (genders.id === 'Females' ||
                          genders.id === 'All genders') &&
                          (`${ageGroup.from} - ${ageGroup.to}` === ages.name ||
                            ages.id === 'All')
                          ? tw`opacity-100`
                          : tw`opacity-25`,
                      ]}
                    >
                      <div tw="h-[3.5rem] flex flex-col justify-end">
                        <p tw="mt-[-2.5rem] mb-[0.25rem]">
                          {females.value.toLocaleString()}
                        </p>
                        <div
                          style={{ height: `${heigthFemale}%` }}
                          css={[
                            tw`rounded-tr-[0.25rem] rounded-tl-[0.25rem]`,
                            employmentsHeroSection.name === 'GLOBAL_EMPLOYED'
                              ? tw`bg-blue-400`
                              : employmentsHeroSection.name ===
                                'GLOBAL_INACTIVE'
                                ? tw`bg-ochre-400`
                                : tw`bg-orange-400`,
                          ]}
                        ></div>
                      </div>
                      <DynamicSvg
                        cssStyle="gender"
                        size={16}
                        iconName="FemaleGender"
                        fill={
                          employmentsHeroSection.name === 'GLOBAL_EMPLOYED'
                            ? theme`colors.blue.350`
                            : employmentsHeroSection.name === 'GLOBAL_INACTIVE'
                              ? theme`colors.ochre.500`
                              : theme`colors.orange.500`
                        }
                      />
                    </div>
                  </div>
                </div>
                <span tw="w-[1px] h-[9.125rem] bg-gray-100 last:hidden"></span>
              </Fragment>
            )
          )}
        </article>
        <div
          css={[
            tw`absolute w-full bottom-[1rem] left-[50%] translate-x-[-50%]`,
          ]}
          style={{ maxWidth: theme`screens.2xl.max` }}
        >
          <CustomSlider />
          <button
            tw="w-[14.688rem] m-[3rem auto 0] border-t border-gray-100 pt-[0.5rem] text-gray-800 flex flex-col items-center gap-[0.5rem] cursor-pointer"
            onClick={() =>
              window.scroll({
                top: document.querySelector('#map_section').offsetTop,
                left: 0,
                behavior: 'smooth',
              })
            }
          >
            {t('GLOBAL_SCROLL')}
            <img src={FlipArrow} alt="Arrow" width={16} />
          </button>
        </div>
      </>
    </section>
  );
};

export default TunisiaHero;

import Map from '../Map/Map';
import Breakdowns from './Breakdowns/Breakdowns';
import MapLegend from '../Map/MapLegend/MapLegend';
import sea from '../../../../assets/images/map_section/sea.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const MatchingGap = ({
  matchingGap,
  jobs,
  tunisiaRegions,
  employmentsMatchingTheGaps,
}) => (
  <>
    <article
      tw="h-full w-[30.875rem] relative"
      css={[
        employmentsMatchingTheGaps === 'GLOBAL_INACTIVE'
          ? tw`box-shadow[5rem 0 3rem #FFFDFA]`
          : tw`box-shadow[5rem 0 3rem #FFFAF4]`,
      ]}
      style={{
        backgroundImage: `url(${matchingGap && sea})`,
        backgroundColor:
          employmentsMatchingTheGaps === 'GLOBAL_INACTIVE'
            ? '#FFFDFA'
            : '#FFFAF4',
      }}
    >
      <Map />
      <MapLegend />
    </article>
    <Breakdowns
      matchingGap={matchingGap}
      jobs={jobs}
      employmentsMatchingTheGaps={employmentsMatchingTheGaps}
      tunisiaRegions={tunisiaRegions}
    />
  </>
);

export default MatchingGap;

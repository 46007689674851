import { useContext } from 'react';
import { TunisiaClockContext } from '../../views/TunisiaClock/TunisiaClockContextProvider';
import { t } from 'i18next';
import { isNotEmptyObject } from '../../utils/common';
import useForm from '../../custom-hooks/useForm';
import checked from '../../assets/images/form/checked.svg';
import close from '../../assets/images/common/close.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const Form = ({ onValidated, status }) => {
  const [{ showModalForm }, dispatch] = useContext(TunisiaClockContext);
  const { formData, handleInputChange, handleReset, handleSubmit } = useForm(
    {
      firstName: '',
      lastName: '',
      companyName: '',
      companyEmail: '',
      message: '',
      joinMailingList: false,
    },
    onValidated
  );

  return (
    showModalForm && (
      <div tw="fixed w-full h-full top-0 left-0 right-0 bottom-0 bg-[rgba(0,0,0, 0.2)] z-[2]">
        <article tw="relative w-[50%] left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] bg-white rounded-[0.5rem] text-center p-[2rem 2.5rem 1.5rem 2.5rem]">
          <button
            onClick={() => {
              dispatch({
                type: 'SHOW_MODAL_FORM',
                payload: showModalForm,
              });
              handleReset();
            }}
            tw="absolute right-[6%]"
          >
            <img src={close} alt="Close" />
          </button>
          <h2 tw="text-blue-400 font-medium text-xxl p-[2rem 0 1rem]">
            {t('FORM_INTEREST')}
          </h2>
          <p tw="max-w-[26.688rem] m-auto text-gray-700 pb-[1.5rem]">
            {t('FORM_INFO')}
          </p>
          <form>
            <div>
              <div tw="flex items-center justify-between gap-[2rem]">
                <input
                  required
                  css={[
                    tw`border border-black rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none`,
                    formData.firstName !== '' && tw`border border-blue-400`,
                  ]}
                  type="text"
                  placeholder={t('FORM_FIRST_NAME')}
                  name="firstName"
                  value={formData.firstName}
                  onChange={(e) => handleInputChange(e.target)}
                />
                <input
                  required
                  css={[
                    tw`border border-black rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none`,
                    formData.lastName !== '' && tw`border border-blue-400`,
                  ]}
                  type="text"
                  placeholder={t('FORM_SURNAME')}
                  name="lastName"
                  value={formData.lastName}
                  onChange={(e) => handleInputChange(e.target)}
                />
              </div>
            </div>
            <input
              required
              css={[
                tw`border border-black rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none my-[1.5rem]`,
                formData.companyEmail !== '' && tw`border border-blue-400`,
              ]}
              type="email"
              placeholder={t('FORM_COMPANY_EMAIL')}
              name="companyEmail"
              value={formData.companyEmail}
              onChange={(e) => handleInputChange(e.target)}
            />
            <input
              required
              css={[
                tw`border border-black rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none`,
                formData.companyName !== '' && tw`border border-blue-400`,
              ]}
              type="text"
              placeholder={t('FORM_COMPANY_NAME')}
              name="companyName"
              value={formData.companyName}
              onChange={(e) => handleInputChange(e.target)}
            />
            <textarea
              required
              tw="border border-black rounded-[2.25rem] p-[0.5rem 1.5rem] w-full focus:border-2 outline-none m-[1.5rem 0 1rem]"
              placeholder={t('FORM_MESSAGE')}
              name="message"
              value={formData.message}
              onChange={(e) => handleInputChange(e.target)}
            ></textarea>
            <div tw="flex">
              <input
                id="joinList"
                tw="mr-[0.5rem] w-[1.5rem] h-[1.5rem]"
                type="checkbox"
                name="joinMailingList"
                checked={formData.joinMailingList}
                onChange={(e) => handleInputChange(e.target)}
              />
              <label htmlFor="joinList">{t('FORM_AGREEMENTS')}</label>
            </div>
            <button
              onClick={handleSubmit}
              type="submit"
              value="Submit"
              disabled={
                !formData.firstName ||
                !formData.lastName ||
                !formData.companyEmail ||
                !formData.companyName ||
                !formData.message
              }
              css={[
                tw`bg-gray-50 border border-gray-50 p-[0.75rem] text-gray-300 w-full text-center rounded-[3.813rem] mb-[0.5rem] mt-[1.5rem]`,
                formData.firstName &&
                  formData.lastName &&
                  formData.companyEmail &&
                  formData.companyName &&
                  formData.message &&
                  tw`bg-blue-400 text-white border border-blue-400 hover:bg-blue-100 hover:text-gray-500`,
              ]}
            >
              {t('GLOBAL_REQUEST_DATA')}
            </button>
          </form>
          <p tw="max-w-[28rem] m-auto">
            {t('FORM_PRIVACY')}{' '}
            <a
              href="https://dashboard.marketpro.io/WDL_Privacy_Policy_FINAL.pdf"
              tw="text-blue-400 underline"
            >
              {t('FORM_PRIVACY_LINK')}
            </a>
            .
          </p>
          {status === 'success' && isNotEmptyObject(formData) && (
            <div tw="absolute left-0 top-0 bottom-0 right-0 flex flex-col items-center justify-between bg-white rounded-[0.5rem] text-center p-[2rem 2.5rem 1.5rem 2.5rem]">
              <img alt="Checked" src={checked} tw="m-[4rem auto 2rem]" />
              <h2 tw="text-blue-400 font-medium text-xxl mb-[1.5rem]">
                {t('FORM_RECEIVED')}
              </h2>
              <p tw="max-w-[33.188rem] w-full text-gray-700 mb-[3rem]">
                {t('FORM_RECEIVED_MESSAGE')}
              </p>
              <button
                onClick={() => {
                  dispatch({
                    type: 'SHOW_MODAL_FORM',
                    payload: showModalForm,
                  });
                  handleReset();
                }}
                value={formData.showReceivedRequest}
                tw="max-w-[11.625rem] w-full rounded-[3.813rem] mb-[3rem] p-[0.5rem 0.75rem] text-white bg-blue-400 hover:bg-blue-100 hover:text-gray-500"
              >
                {t('FORM_CLOSE')}
              </button>
            </div>
          )}
          {status === 'error' && isNotEmptyObject(formData) && (
            <div tw="absolute left-0 top-0 bottom-0 right-0 flex flex-col items-center justify-between bg-white rounded-[0.5rem] text-center p-[2rem 2.5rem 1.5rem 2.5rem]">
              <img alt="Checked" src={checked} tw="m-[4rem auto 2rem]" />
              <h2 tw="text-blue-400 font-medium text-xxl mb-[1.5rem]">
                {t('FORM_ERROR')}
              </h2>
              <button
                onClick={() => {
                  dispatch({
                    type: 'SHOW_MODAL_FORM',
                    payload: showModalForm,
                  });
                  handleReset();
                }}
                value={formData.showReceivedRequest}
                tw="max-w-[11.625rem] w-full rounded-[3.813rem] mb-[3rem] p-[0.5rem 0.75rem] text-white bg-blue-400 hover:bg-blue-100 hover:text-gray-500"
              >
                {t('FORM_CLOSE')}
              </button>
            </div>
          )}
        </article>
      </div>
    )
  );
};

export default Form;

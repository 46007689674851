import { t } from 'i18next';
import DynamicSvg from '../../../../../../DynamicSvg/DynamicSvg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const SubSectorsOpenJobsEmployments = ({
  matchingGap,
  employmentsMatchingTheGaps,
}) => (
  <article tw="w-full relative">
    <>
      <div tw="flex items-center justify-between text-gray-500 text-xs font-semiBold border-b border-b-gray-100 p-[0 0.5rem 0.5rem]">
        <span>{t('GLOBAL_REGION')}</span>
        <span>{t(employmentsMatchingTheGaps.name)}</span>
      </div>
      <div className="scrollable" tw="h-[15rem] overflow-y-scroll">
        {matchingGap?.values?.map(({ overall, region }) => (
          <div
            key={region?.name}
            tw="flex justify-between items-center p-[0.20rem 0.5rem] even-of-type:bg-tableGreen"
          >
            <span tw="text-gray-800">{region?.name}</span>
            <span tw="flex items-center gap-[0.5rem] justify-between pl-[1.438rem]">
              <p tw="text-green-500 font-medium text-sm line-height[1.026rem]">
                {overall?.value.toLocaleString()}
              </p>
              <DynamicSvg iconName={overall?.direction} size={10} />
            </span>
          </div>
        ))}
      </div>
    </>
  </article>
);

export default SubSectorsOpenJobsEmployments;

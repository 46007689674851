import Map from '../Map/Map';
import Breakdowns from './Breakdowns/Breakdowns';
import MapLegend from '../Map/MapLegend/MapLegend';
import sea from '../../../../assets/images/map_section/sea.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const JobOpening = ({ data, tunisiaRegions, insightsData }) => (
  <>
    <article
      tw="h-full w-[30.875rem] relative box-shadow[5rem 0 3rem #E7FFF7]"
      style={{
        backgroundImage: `url(${data && sea})`,
        backgroundColor: '#E7FFF7',
      }}
    >
      <Map />
      <MapLegend />
    </article>
    <Breakdowns
      data={data}
      tunisiaRegions={tunisiaRegions}
      insightsData={insightsData}
    />
  </>
);

export default JobOpening;

import { t } from 'i18next';
import logo from '../../assets/images/header/logo.svg';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const Logo = () => (
  <>
    <img tw="pr-4" src={logo} alt="Tunisia Jobs Clock Logo" />
    <h1 tw="text-[0.625rem] text-blue-400">
      {t('HEADER_LOGO_MAIN')}
      <span tw="block text-gray-300">{t('HEADER_LOGO_SUB')}</span>
    </h1>
  </>
);

export default Logo;

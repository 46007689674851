import { useContext } from 'react';
import { TunisiaClockContext } from '../../views/TunisiaClock/TunisiaClockContextProvider';
// import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { TRIGGER_NAVBAR } from '../../reducer/reducer';
import { Grid, Col } from '../../assets/styles/grid';
import Logo from './Logo';
import { CheckBox, CheckBoxLabel } from '../ToggleSwitch/Checkbox';
import { removeActiveItemFromList } from '../../utils/common';
import useToggle from '../../custom-hooks/useToggle';
import whiteArrow from '../../assets/images/header/whiteArrow.svg';
import reset from '../../assets/images/header/whiteReset.svg';
import close from '../../assets/images/header/close.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const NavbarMapSection = () => {
  const [state, dispatch] = useContext(TunisiaClockContext);
  const [{ gendersToggle, agesToggle }, handleToggleState] = useToggle();
  const { t, i18n } = useTranslation();

  const handleNavItemClick = (sectorOrSectionId) => {
    dispatch({
      type: 'SET_LATEST_TRIGGER',
      payload: TRIGGER_NAVBAR,
    });
    dispatch({
      type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
      payload: { id: sectorOrSectionId, type: 'activeSection' },
    });
    dispatch({
      type: 'SET_ACTIVE_SECTOR_OR_SECTION_OR_REGION',
      payload: {
        type: 'activeRegion',
        name: 'GLOBAL_TUNISIA',
        id: 'Tunisia',
      },
    });
    dispatch({
      type: 'SET_ACTIVE_FILTER',
      payload: {
        type: 'ages',
        id: 'All',
        name: 'GLOBAL_ALL_AGES',
      },
    });
    dispatch({
      type: 'SET_ACTIVE_FILTER',
      payload: {
        type: 'genders',
        id: 'All genders',
        name: 'GLOBAL_ALL_GENDERS',
      },
    });
  };

  return (
    <div tw="bg-white shadow z-[401] w-full relative">
      <Grid as="header" cols={12} alignItems="center">
        <Col col={3} md={3} tw="flex">
          <Logo />
        </Col>
        <Col col={9} md={9}>
          <nav>
            <ul tw="flex justify-end items-center gap-[2.06rem] text-blue-400 text-xs">
              <li
                css={[
                  tw`text-xs relative hover:font-medium focus:font-medium`,
                  state.activeSection === 'MENU_WORKFORCE' && tw`font-medium`,
                ]}
                onClick={() => handleNavItemClick('MENU_WORKFORCE')}
              >
                <button>{t('MENU_WORKFORCE')}</button>
                <span
                  css={[
                    tw`absolute h-0 bg-blue-400 bottom-[-1.625rem] left-0 w-full`,
                    state.activeSection === 'MENU_WORKFORCE' && tw`h-[2px]`,
                  ]}
                ></span>
              </li>
              <li
                css={[
                  tw`text-xs relative hover:font-medium focus:font-medium`,
                  state.activeSection === 'MENU_JOB_OPENINGS' &&
                    tw`font-medium`,
                ]}
                onClick={() => handleNavItemClick('MENU_JOB_OPENINGS')}
              >
                <button>{t('MENU_JOB_OPENINGS')}</button>
                <span
                  css={[
                    tw`absolute h-0 bg-blue-400 bottom-[-1.625rem] left-0 w-full`,
                    state.activeSection === 'MENU_JOB_OPENINGS' && tw`h-[2px]`,
                  ]}
                ></span>
              </li>
              <li
                css={[
                  tw`text-xs relative hover:font-medium focus:font-medium`,
                  state.activeSection === 'MENU_MATCHING_GAPS' &&
                    tw`font-medium`,
                ]}
                onClick={() => handleNavItemClick('MENU_MATCHING_GAPS')}
              >
                <button>{t('MENU_MATCHING_GAPS')}</button>
                <span
                  css={[
                    tw`absolute h-0 bg-blue-400 bottom-[-1.625rem] left-0 w-full`,
                    state.activeSection === 'MENU_MATCHING_GAPS' && tw`h-[2px]`,
                  ]}
                ></span>
              </li>
            </ul>
          </nav>
          <nav>
            <ul
              css={[
                tw`flex justify-start gap-[2rem] text-xs items-center absolute w-full left-0 bg-blue-400 text-white p-[0.75rem 11.3125rem] top-[4.1rem] opacity-0 transition duration-500 shadow invisible`,
                state.showFiltersBar &&
                  state.activeSection === 'MENU_WORKFORCE' &&
                  tw`opacity-100 visible`,
              ]}
            >
              <li tw="hover:text-blue-300 cursor-pointer">
                {t('FILTER_MAP_BAR_VIEW')}
              </li>
              <li
                tw="hover:text-blue-300 cursor-pointer relative border-b border-b-blue-350 flex p-[0.625rem]"
                onClick={() =>
                  handleToggleState(gendersToggle, 'gendersToggle')
                }
              >
                {t(`${state.genders.name}`)}
                <img
                  css={[
                    tw`ml-[1rem] transition duration-500`,
                    gendersToggle && tw`rotate-180`,
                  ]}
                  alt="Arrow"
                  width={14}
                  src={whiteArrow}
                />
                {gendersToggle && (
                  <ul tw="text-gray-800 text-xs absolute top-[2.35rem] left-0 min-w-[5.31rem] w-full cursor-pointer shadow bg-white">
                    {removeActiveItemFromList('genders', state, dispatch, t)}
                  </ul>
                )}
              </li>
              <li>{t('FILTER_MAP_BAR_AND')}</li>
              <li
                tw="hover:text-blue-300 cursor-pointer relative border-b border-b-blue-350 flex p-[0.625rem]"
                onClick={() => handleToggleState(agesToggle, 'agesToggle')}
              >
                {t(`${state.ages.name}`)}
                <img
                  css={[
                    tw`ml-[1rem] transition duration-500`,
                    agesToggle && tw`rotate-180`,
                  ]}
                  alt="Arrow"
                  width={14}
                  src={whiteArrow}
                />
                {agesToggle && (
                  <ul tw="text-gray-800 text-xs absolute top-[2.35rem] left-0 min-w-[5.31rem] w-full cursor-pointer shadow bg-white">
                    {removeActiveItemFromList('ages', state, dispatch, t)}
                  </ul>
                )}
              </li>
              <li tw="flex items-center gap-[0.563rem] justify-between relative">
                <CheckBox
                  id="view_sector_breakdown"
                  type="checkbox"
                  checked={state.showBreakdownBar}
                  onChange={() =>
                    dispatch({
                      type: 'TOGGLE_FILTER_AND_BREAKDOWN_BAR',
                      payload: {
                        type: 'showBreakdownBar',
                        showBreakdownBar: state.showBreakdownBar,
                      },
                    })
                  }
                />
                <CheckBoxLabel htmlFor="view_sector_breakdown" />
                <span>{t('VIEW_SECTOR_BREAKDOWN')}</span>
              </li>
              <li
                css={[
                  tw`absolute right-[15.3125rem] text-gray-200 flex justify-center items-center p-[0.56rem 1rem] gap-[0.25rem] h-[2.215rem] w-[8.43rem] border border-blue-350 rounded-[3.37rem] opacity-0 transition duration-500 cursor-pointer`,
                  state.reset && tw`opacity-100 text-white border-blue-350`,
                  i18n.language === 'fr' && tw`w-[11.43rem]`
                ]}
                onClick={() => {
                  dispatch({
                    type: 'TOGGLE_LOADING_NEW_MAP_DATA',
                    payload: true,
                  });
                  dispatch({
                    type: 'RESET_FILTERS',
                  });
                }}
              >
                <img alt="Reset" src={reset} />
                <span>{t('GLOBAL_FILTERS_RESET')}</span>
              </li>
              <li
                tw="absolute right-[11.3125rem] text-gray-200 flex justify-center items-center border border-blue-350 rounded-[50%] cursor-pointer h-[2.215rem] w-[2.215rem] hover:border-white"
                onClick={() =>
                  dispatch({
                    type: 'TOGGLE_FILTER_AND_BREAKDOWN_BAR',
                    payload: {
                      type: 'showFiltersBar',
                      showFiltersBar: state.showFiltersBar,
                    },
                  })
                }
              >
                <img alt="Close" src={close} width={14} />
              </li>
            </ul>
          </nav>
        </Col>
      </Grid>
    </div>
  );
};

export default NavbarMapSection;

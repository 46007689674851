import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import SubSectorsOpenJobsEmployments from './SubSectorsOpenJobs/SubSectorsOpenJobsEmployments';
import { t } from 'i18next';
import AgeAndGender from '../AgeAndGender/AgeAndGender';
import FlipArrow from '../../../../../../assets/images/hero_section/flipArrow.svg';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';

const RegionsAndSectorsEmployments = ({ matchingGap }) => {
  const [
    { showMatchingTheGapsRegions, employmentsMatchingTheGaps, activeRegion },
    dispatch,
  ] = useContext(TunisiaClockContext);

  return (
    <div tw="w-full mb-[1rem] rounded-[1.25rem] border border-gray-100 shadow p-[0.75rem 1rem] cursor-pointer">
      <div
        tw="flex justify-between"
        onClick={() =>
          dispatch({
            type: 'TOGGLE_FILTER_AND_BREAKDOWN_BAR',
            payload: {
              type: 'showMatchingTheGapsRegions',
              showMatchingTheGapsRegions: showMatchingTheGapsRegions,
            },
          })
        }
      >
        <h3 tw="text-blue-400 text-xs font-medium">
          {activeRegion.id === 'Tunisia'
            ? t('GLOBAL_REGIONS')
            : t('GLOBAL_AGE_GENDER')}
        </h3>
        <img
          css={[
            tw`transition duration-500`,
            showMatchingTheGapsRegions && tw`rotate-180`,
          ]}
          alt="Flip arrow"
          src={FlipArrow}
        />
      </div>
      {showMatchingTheGapsRegions && matchingGap && (
        <div tw="flex pt-[1rem] text-[0.875rem]">
          {activeRegion.id === 'Tunisia' ? (
            <SubSectorsOpenJobsEmployments
              matchingGap={matchingGap}
              employmentsMatchingTheGaps={employmentsMatchingTheGaps}
            />
          ) : (
            <AgeAndGender matchingGap={matchingGap} />
          )}
        </div>
      )}
    </div>
  );
};

export default RegionsAndSectorsEmployments;

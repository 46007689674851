import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import AgeAndGenderInner from '../../Unemployed/AgeAndGender/AgeAndGenderInner/AgeAndGenderInner';

const AgeAndGenderInactive = ({ data, type }) => {
  const [{ ages, genders, employmentsMapSection }] =
    useContext(TunisiaClockContext);

  return (
    data && (
      <AgeAndGenderInner
        data={data}
        ages={ages}
        genders={genders}
        type={type}
        employmentsMapSection={employmentsMapSection}
      />
    )
  );
};

export default AgeAndGenderInactive;

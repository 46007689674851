import { useCallback, useState } from 'react';

const useToggle = () => {
  const [toggleState, setToggleState] = useState({
    employmentsToggle: false,
    gendersToggle: false,
    agesToggle: false,
    regionsToggle: false,
    breakdownToggle: false,
    showFiltersBarToggle: false,
    insightsToggle: false,
    subSectorsToggle: false,
    languageToggle: false
  });

  const handleToggleState = useCallback(
    (prevState, type) =>
      setToggleState({
        ...toggleState,
        [type]: !prevState,
      }),
    [toggleState]
  );

  return [toggleState, handleToggleState];
};

export default useToggle;

import { t } from 'i18next';
import { links } from './SocialLinks';
/** @jsxImportSource @emotion/react */
import 'twin.macro';

const FooterSocials = () => (
  <>
    <h2 tw="uppercase font-bold">{t('FOOTER_CONTACT_US')}</h2>
    <a href="mailto:tjc@worlddata.io">tjc@worlddata.io</a>
    <div tw="flex mt-[1rem]">
      {links.map(({ name, image, url }) => (
        <a
          key={name}
          href={url}
          tw="mr-[0.93rem] shadow bg-white max-w-[2.5rem] w-full h-[2.5rem] p-[0.625rem] flex rounded-[50%]"
        >
          <img src={image} alt={name} />
        </a>
      ))}
    </div>
  </>
);

export default FooterSocials;

import { theme } from 'twin.macro';

const OVERALL_X_HIGH = 80000;
const OVERALL_X_MIDDLE = 50000;

const OVERALL_Y_HIGH = 10000;
const OVERALL_Y_MIDDLE = 5000;

const getColor = (overallPercentage) =>
  overallPercentage > 40
    ? `${theme`colors.blue.500`}`
    : overallPercentage > 30
    ? `${theme`colors.blue.400`}`
    : overallPercentage > 20
    ? `${theme`colors.blue.300`}`
    : overallPercentage > 10
    ? `${theme`colors.blue.200`}`
    : `${theme`colors.blue.100`}`;

const getInactiveColor = (overallPercentage) =>
  overallPercentage > 70
    ? `${theme`colors.ochre.500`}`
    : overallPercentage > 65
    ? `${theme`colors.ochre.400`}`
    : overallPercentage > 60
    ? `${theme`colors.ochre.300`}`
    : overallPercentage > 55
    ? `${theme`colors.ochre.200`}`
    : `${theme`colors.ochre.100`}`;

const getUnemployedColor = (overallPercentage) =>
  overallPercentage > 11
    ? `${theme`colors.orange.500`}`
    : overallPercentage > 9
    ? `${theme`colors.orange.400`}`
    : overallPercentage > 7
    ? `${theme`colors.orange.300`}`
    : overallPercentage > 5
    ? `${theme`colors.orange.200`}`
    : `${theme`colors.orange.100`}`;

const getInactiveAndUnemployedColor = (overallPercentage) =>
  overallPercentage > 80
    ? `${theme`colors.orange.500`}`
    : overallPercentage > 75
    ? `${theme`colors.orange.400`}`
    : overallPercentage > 70
    ? `${theme`colors.orange.300`}`
    : overallPercentage > 65
    ? `${theme`colors.orange.200`}`
    : `${theme`colors.orange.100`}`;

const getJobOpeningsColor = (overallPercentage) =>
  overallPercentage > 1.5
    ? `${theme`colors.green.500`}`
    : overallPercentage > 1.2
    ? `${theme`colors.green.400`}`
    : overallPercentage > 1
    ? `${theme`colors.green.300`}`
    : overallPercentage > 0.75
    ? `${theme`colors.green.200`}`
    : `${theme`colors.green.100`}`;

const getMatchingGapsColor = (scenarioOverallX, scenarioOverallY) => {
  if (scenarioOverallY > OVERALL_Y_HIGH) {
    if (scenarioOverallX > OVERALL_X_HIGH) return '#3B8D73';
    else if (scenarioOverallX > OVERALL_X_MIDDLE) return '#4A8ECD';
    else return '#014B9C';
  } else if (scenarioOverallY > OVERALL_Y_MIDDLE) {
    if (scenarioOverallX > OVERALL_X_HIGH) return '#CED074';
    else if (scenarioOverallX > OVERALL_X_MIDDLE) return '#C7E99A';
    else return '#6AA499';
  } else {
    if (scenarioOverallX > OVERALL_X_HIGH) return '#FF6600';
    else if (scenarioOverallX > OVERALL_X_MIDDLE) return '#EF962E';
    else return '#E8AE45';
  }
};

export const regionStyle = (
  { scenarioOverall, scenarioOverallX, scenarioOverallY, population },
  employmentsMapSection,
  activeSection
) => {
  let fillColor;
  const overallPercentage = (scenarioOverall * 100) / population;

  activeSection === 'MENU_MATCHING_GAPS'
    ? (fillColor = getMatchingGapsColor(scenarioOverallX, scenarioOverallY))
    : activeSection === 'MENU_JOB_OPENINGS'
    ? (fillColor = getJobOpeningsColor(overallPercentage))
    : employmentsMapSection === 'GLOBAL_INACTIVE'
    ? (fillColor = getInactiveColor(overallPercentage))
    : employmentsMapSection === 'GLOBAL_UNEMPLOYED'
    ? (fillColor = getUnemployedColor(overallPercentage))
    : employmentsMapSection === 'GLOBAL_INACTIVE_UNEMPLOYED'
    ? (fillColor = getInactiveAndUnemployedColor(overallPercentage))
    : (fillColor = getColor(overallPercentage));

  return {
    fillColor,
    fillOpacity: 1,
    weight: 2.5,
    color: 'white',
  };
};

import useMousePosition from '../../../../../custom-hooks/useMousePosition';
import Tooltip from './Tooltip';

const TooltipContainer = ({
  tooltipInfo,
  employmentsMapSection,
  employmentsMatchingTheGaps,
  activeSection,
}) => {
  const position = useMousePosition();

  return (
    tooltipInfo && (
      <Tooltip
        tooltipInfo={tooltipInfo}
        position={position}
        employmentsMapSection={employmentsMapSection}
        employmentsMatchingTheGaps={employmentsMatchingTheGaps}
        activeSection={activeSection}
      />
    )
  );
};

export default TooltipContainer;

import { useContext } from 'react';
import { TunisiaClockContext } from '../../../../../../../../views/TunisiaClock/TunisiaClockContextProvider';
import Loader from '../../../../../../../Loader/Loader';
import { t } from 'i18next';
import useToggle from '../../../../../../../../custom-hooks/useToggle';
import DynamicSvg from '../../../../../../../DynamicSvg/DynamicSvg';
import arrow from '../../../../../../../../assets/images/common/arrow.svg';
/** @jsxImportSource @emotion/react */
import tw, { theme } from 'twin.macro';

const SubSectorsAgeAndGender = ({ data }) => {
  const [{ activeSector, activeSubSector, ages, genders }, dispatch] =
    useContext(TunisiaClockContext);
  const [{ subSectorsToggle }, handleToggleState] = useToggle();

  const checkSubSectorsLength = (data) =>
    data?.find(({ sector }) => sector?.id === activeSector)?.subSectors
      ?.length > 1;

  const finalData = (data) =>
    data
      ?.find(({ sector }) => sector?.id === activeSector)
      .subSectors?.find(({ sector }) => sector?.name === activeSubSector);

  return (
    <article tw="pl-[0.75rem] w-full relative">
      {!activeSubSector ? (
        <Loader message={t("LOADER_SUBSECTORS_MESSAGE")} />
      ) : (
        <>
          <h4 tw="text-gray-500 font-semiBold text-xs">
            {t('SECTORS_SUBSECTORS')}
          </h4>
          <div tw="flex justify-between pb-[0.4rem]">
            <li
              tw="hover:text-blue-300 cursor-pointer relative border-b border-b-blue-400 flex items-center p-[0.625rem] mr-[0.625rem] justify-between w-full h-[3.93rem]"
              {...(checkSubSectorsLength(data) && {
                onClick: () =>
                  handleToggleState(subSectorsToggle, 'subSectorsToggle'),
              })}
            >
              {activeSubSector}
              {checkSubSectorsLength(data) && (
                <img
                  css={[
                    tw`ml-[1rem] transition duration-500`,
                    subSectorsToggle && tw`rotate-180`,
                  ]}
                  alt="Arrow"
                  width={14}
                  src={arrow}
                />
              )}
              {subSectorsToggle && (
                <ul
                  tw="text-gray-800 text-xs absolute top-[4rem] left-0 w-full cursor-pointer shadow bg-white max-h-[11.3rem] overflow-y-scroll text-left z-[1]"
                  className="scrollable scrollableRegions"
                >
                  {data
                    ?.find(({ sector }) => sector?.id === activeSector)
                    .subSectors.filter(
                      ({ sector }) => sector.name !== activeSubSector
                    )
                    ?.map(({ sector }) => (
                      <li
                        tw="p-[1rem] border-b border-b-gray-100"
                        id={sector?.name}
                        key={sector?.name}
                        onClick={() =>
                          dispatch({
                            type: 'SET_ACTIVE_FILTER',
                            payload: {
                              type: 'activeSubSector',
                              name: sector?.name,
                            },
                          })
                        }
                      >
                        <span tw="text-sm text-gray-800">{sector?.name}</span>
                      </li>
                    ))}
                </ul>
              )}
            </li>
            <span tw="flex items-center gap-[0.5rem] justify-between pl-[1.438rem]">
              <p tw="font-medium text-blue-400">
                {finalData(data)?.overall?.value.toLocaleString()}
              </p>
              <DynamicSvg
                iconName={finalData(data)?.overall?.direction}
                size={12}
              />
            </span>
          </div>
          <div tw="flex items-center justify-between text-gray-500 font-semiBold border-b border-b-gray-100 p-[0 0.5rem 0.5rem]">
            <span>{t('GLOBAL_AGE')}</span>
            <DynamicSvg
              iconName="MaleGender"
              size={16}
              fill={theme`colors.gray.500`}
            />
            <DynamicSvg
              iconName="FemaleGender"
              size={16}
              fill={theme`colors.gray.500`}
            />
          </div>
          {finalData(data)?.ageGenderValues.map(
            ({ ageGroup, females, males }) => (
              <div
                key={ageGroup?.from}
                tw="flex justify-between items-center p-[0.20rem 0.5rem] even-of-type:bg-tableBlue"
              >
                <span
                  css={[
                    tw`text-gray-800 text-sm w-[20%]`,
                    `${ageGroup.from} - ${ageGroup.to}` === ages.name ||
                    ages.id === 'All'
                      ? tw`opacity-100`
                      : tw`opacity-25`,
                  ]}
                >
                  {`${ageGroup?.from} - ${ageGroup?.to}`}
                </span>
                <span
                  css={[
                    tw`font-medium text-sm flex gap-[0.5rem] items-center text-blue-400 flex-1 justify-end`,
                    (genders.id === 'Males' || genders.id === 'All genders') &&
                    (`${ageGroup.from} - ${ageGroup.to}` === ages.name ||
                      ages.id === 'All')
                      ? tw`opacity-100`
                      : tw`opacity-25`,
                  ]}
                >
                  {males?.value.toLocaleString()}
                  <DynamicSvg iconName={males?.direction} size={10} />
                </span>
                <span
                  css={[
                    tw`font-medium text-sm flex gap-[0.5rem] items-center text-blue-400 flex-1 justify-end`,
                    (genders.id === 'Females' ||
                      genders.id === 'All genders') &&
                    (`${ageGroup.from} - ${ageGroup.to}` === ages.name ||
                      ages.id === 'All')
                      ? tw`opacity-100`
                      : tw`opacity-25`,
                  ]}
                >
                  {females?.value.toLocaleString()}
                  <DynamicSvg iconName={females?.direction} size={10} />
                </span>
              </div>
            )
          )}
          <div tw="flex justify-between items-center p-[0.20rem 0.5rem] even-of-type:bg-tableBlue">
            <span
              css={[
                tw`text-gray-800 text-sm w-[20%]`,
                ages.id === 'All' ? tw`opacity-100` : tw`opacity-25`,
              ]}
            >
              Total
            </span>
            <span
              css={[
                tw`font-medium text-sm flex gap-[0.5rem] items-center text-blue-400 flex-1 justify-end`,
                (genders.id === 'Males' || genders.id === 'All genders') &&
                ages.id === 'All'
                  ? tw`opacity-100`
                  : tw`opacity-25`,
              ]}
            >
              {finalData(data)?.males?.value.toLocaleString()}
              <DynamicSvg
                iconName={finalData(data)?.males?.direction}
                size={10}
              />
            </span>
            <span
              css={[
                tw`font-medium text-sm flex gap-[0.5rem] items-center text-blue-400 flex-1 justify-end`,
                (genders.id === 'Females' || genders.id === 'All genders') &&
                ages.id === 'All'
                  ? tw`opacity-100`
                  : tw`opacity-25`,
              ]}
            >
              {finalData(data)?.females?.value.toLocaleString()}
              <DynamicSvg
                iconName={finalData(data)?.females?.direction}
                size={10}
              />
            </span>
          </div>
        </>
      )}
    </article>
  );
};

export default SubSectorsAgeAndGender;
